import React from 'react';
import { action } from 'mobx';
import { Link, useLocation } from 'react-router-dom';

// Helpers
import { getUrlSegment } from '../../helpers';

// Routes
import routes from '../../routes';

// Dependencies
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import Icon from 'alp-icons';

// Store
import { withStore } from '../../stores/RootStore';

// Logo
import Logo from '../ui/Logo';

// Common components
import Avatar from '../common/Avatar';

/**
 * Renders the main app header
 * 
 * @param {object} props
 * @returns {Function}
 */
const Header = props => {
  // Get values from props
  const { store } = props;
  const { dialogStore, rootStore, postStore, translationStore, userStore } = store;
  const { translation } = translationStore;
  const { loggedIn, userData } = userStore;

  // Use location to determine document title
  const { pathname } = useLocation();

  // Main useEffect loop
  React.useEffect(async () => {
    // Check if getUserMedia is supported.
    // Do this here because if the header
    // is present, the user is logged in.
    if(loggedIn && !rootStore.recordingChecked) {
      action(() => {
        rootStore.recordingChecked = true;
      })();

      try {
        // Register the wav mimetype for recording
        await register(await connect());

        // Make sure we can get user media
        const stream = await navigator.mediaDevices.getUserMedia({ audio: { volume: 1.0 }, video: false });

        stream.getTracks().forEach(track => {
          track.stop();
        })
      } catch(error) {
        console.log(error);

        action(() => {
          rootStore.recordingSupported = false;
        })();

        const translationDelay = setInterval(() => {
          clearInterval(translationDelay);

          if (translation) {
            dialogStore.showDialog({ content: translation.Unsupported.audio_recording });
          }
        }, 100);
      }
    }
  }, []);

  // Listen for pathname changes
  React.useEffect(() => {
    updateDocTitle();
  }, [pathname])

  /**
   * Update the document title
   * 
   * @function updateDocTitle
   */
  const updateDocTitle = () => {
    if (getUrlSegment(1) === 'post') {
      return;
    }

    const matchedRoute = Object.keys(routes).find(key => routes[key] === pathname);
    let newTitle;

    if (matchedRoute) {
      // If the pathname matches a static route (e.g. 'settings'),
      // use that route’s name from the translation.
      newTitle = translation?.Nav[matchedRoute]
    } else {
      // Otherwise, assume it’s a dynamic route like a profile page
      // as long as it isn’t a post page which handles the title
      newTitle = getUrlSegment(1)
    }

    document.title = `${newTitle} | ${translation?.App.title}`;
  }

  return (
    <header
      className="app-head"
      role="banner"
    >
      <h1 className="meta">Cheezr</h1>

      <Link
        className="brand brand--main"
        to="/"
      >
        <Logo />

        <span className="brand__tooltip">
          {translation.Nav.index}
        </span>
      </Link>

      {loggedIn &&
        <nav className="app-head__nav">
          {rootStore.recordingSupported &&
            <button
              className="app-head__new-post btn btn--pill"
              onClick={postStore.newPost}
            >
              <Icon name="microphone" />
              <span className="mobile-hide">{translation.Nav.new_post}</span>
            </button>
          }

          <Link
            className="app-head__user avatar-wrap title-link"
            to={`/${userData?.uname}`}
          >
            <Avatar
              photoUrl={userData?.photoURL}
              size="small"
              storeUrl
              username={userData?.uname}
            />

            <span className="mobile-hide">{userData?.uname}</span>
          </Link>
        </nav>
      }
    </header>
  )
}

export default withStore(Header);
