import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Routes
import routes from '../routes';

// Import UI components
import Footer from './ui/Footer';
import Header from './ui/Header';
import Nav from './ui/Nav';

// Import views
import Home from './views/Home';
import Error404 from './views/Error404';
import ErrorExpired from './views/ErrorExpired';
import ErrorUnauthorized from './views/ErrorUnauthorized';
import PostDetail from './views/PostDetail';
import PrivacyPolicy from './views/PrivacyPolicy';
import Profile from './views/Profile';
import Search from './views/Search';
import Settings from './views/Settings';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import Tags from './views/Tags';
import Terms from './views/Terms';

/**
 * Renders the main app router
 * 
 * @param {object} props
 * @returns {Function}
 */
const AppRouter = props => {
  // Get values from props
  const { loggedIn } = props;

  return (
    <BrowserRouter>
      {loggedIn &&
        <a
          className="nav-toggle"
          href="#nav-primary"
          onClick={e => {
            e.preventDefault();
            document.body.classList.toggle('nav-visible');
          }}
        >
          <span className="meta">Navigation</span>
        </a>
      }

      <Header />

      {loggedIn &&
        <Nav />
      }

      <Routes location={location}>
        <Route
          exact
          path={routes.index}
          element={<Home {...props} history={history} />}
        />

        <Route
          path={routes.post}
          element={<PostDetail {...props} history={history} />}
        />

        <Route
          exact
          path={routes.settings}
          element={<Settings {...props} history={history} />}
        />

        <Route
          exact
          path={routes.search}
          element={<Search {...props} history={history} />}
        />

        <Route
          exact
          path={routes.tags}
          element={<Tags {...props} history={history} />}
        />

        {/* <Route
          exact
          path={routes.messages}
          element={<Messages {...props} history={history} />}
        /> */}

        <Route
          path={routes.profile}
          element={<Profile {...props} history={history} />}
        />

        <Route
          exact
          path={routes.signIn}
          element={<SignIn {...props} history={history} />}
        />

        <Route
          exact
          path={routes.signUp}
          element={<SignUp {...props} history={history} />}
        />

        <Route
          exact
          path={routes.privacy}
          element={<PrivacyPolicy {...props} history={history} />}
        />

        <Route
          exact
          path={routes.terms}
          element={<Terms {...props} history={history} />}
        />

        <Route
          path={routes.expired}
          element={<ErrorExpired {...props} history={history} />}
        />

        <Route
          path={routes.unauthorized}
          element={<ErrorUnauthorized {...props} history={history} />}
        />

        <Route
          path="*"
          element={<Error404 {...props} history={history} />}
        />
      </Routes>

      <Footer />
    </BrowserRouter>
  )
}

export default AppRouter;
