import { action, makeObservable, observable } from 'mobx';

/**
 * Handle toast messages
 * 
 * @function ToastStore
 */
export default class ToastStore {
  /*-------------------------------------------
    Toast message
  -------------------------------------------*/
  toast = null;
  toastOnDismiss = null;
  toastType = null;
  toastTimeout = 5000;

  /**
   * Set up observable variables
   */
  constructor() {
    makeObservable(this, {
      toast: observable,
      toastOnDismiss: observable,
      toastType: observable,
      toastTimeout: observable
    });
  }

  /**
   * Show a toast message
   * 
   * @function showToast
   * @param {string} content
   * @param {string} type
   * @param {Function} onDismiss
   * @param {number} timeout
   */
  showToast = action((content, type, onDismiss, timeout = 5000) => {
    this.toast = content;
    this.toastType = type;
    this.toastTimeout = timeout;

    if (onDismiss) {
      this.toastOnDismiss = onDismiss;
    }
  })

  /**
   * Close a toast message
   * 
   * @function closeToast
   * @param {object} content
   */
  closeToast = action(() => {
    this.toast = null;
    this.toastOnDismiss = null;
    this.toastType = null;
    this.toastTimeout = 5000;
  })
}
