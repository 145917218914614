import React from 'react';

// Store
import { withStore } from '../../stores/RootStore';

// Dependencies
import Icon from 'alp-icons';

/**
 * Renders a toast message component
 * 
 * @param {object} props
 * @property {string} content
 * @property {Function} [onDismiss]
 * @property {number} [timeout]
 * @returns {Function}
 */
const Toast = props => {
  // Keep track of mount status to prevent updating state after unmount
  let _isMounted = true;

  // Get values from props
  const {
    content,
    onDismiss,
    timeout,
    type,
    store
  } = props;
  const { toastStore } = store;

  // Set up state
  const [exiting, setExiting] = React.useState(false);

  // Create refs
  const toastEl = React.createRef();

  // Set up timeout handler
  let toastTimeout = null;

  // Main useEffect hook
  React.useEffect(() => {
    startTimeout();

    toastEl?.current?.addEventListener('mouseenter', cancelTimeout, false);
    toastEl?.current?.addEventListener('mouseleave', startTimeout, false);

    return () => {
      cancelTimeout();
      toastEl?.current?.removeEventListener('mouseenter', cancelTimeout, false);
      toastEl?.current?.removeEventListener('mouseleave', startTimeout, false);
      _isMounted = false;
    }
  }, [exiting]);

  /**
   * Remove the toast message after the set amount of time
   * 
   * @function startTimeout
   */
  const startTimeout = () => {
    if (!timeout) {
      return;
    }

    toastTimeout = setTimeout(() => {
      handleDismiss();
    }, timeout);
  }

  /**
   * Cancel the timeout
   * 
   * @function cancelTimeout
   */
  const cancelTimeout = () => {
    clearTimeout(toastTimeout);
  }

  /**
   * Dismiss the toast message
   * 
   * @function handleDismiss
   */
  const handleDismiss = () => {
    if (_isMounted) {
      setExiting(true);
    }

    clearTimeout(toastTimeout);

    // Wait for the animation to finish
    // and then remove the toast.
    setTimeout(() => {
      toastStore.closeToast();

      if (onDismiss) {
        onDismiss();
      }
    }, 250);
  }

  /**
   * Render the appropriate icon
   * 
   * @function renderIcon
   * @param {string} type
   * @returns {Function}
   */
  const renderIcon = type => {
    let icon;

    switch (type) {
      case 'positive':
        icon = <Icon name="checkCircle" />;
        break;

      // TODO: Add icon
      case 'warn':
        icon = <Icon name="infoCircle" />;
        break;

      // TODO: Add icon
      case 'negative':
        icon = <Icon name="infoCircle" />;
        break;

      default:
        icon = <Icon name="infoCircle" />;
    }

    return icon;
  }

  return (
    <aside
      className={`toast${
        type ? ` toast--${type}` : ''
      }${
        exiting ? ' toast--exiting' : ''
      }`}
      ref={toastEl}
    >
      <i className="toast__icon">
        {renderIcon(type)}
      </i>

      <span className="toast__content">
        {content}
      </span>

      <button
        className="toast__close"
        onClick={handleDismiss}
      >
        <Icon name="times" />
        <span className="meta">Dismiss</span>
      </button>
    </aside>
  )
}

export default withStore(Toast);
