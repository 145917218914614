import RootStore from './RootStore';
import AvatarStore from './AvatarStore';
import DialogStore from './DialogStore';
import DrawerStore from './DrawerStore';
import PostStore from './PostStore';
import ToastStore from './ToastStore';
import TranslationStore from './TranslationStore';
import UserStore from './UserStore';

const avatarStore = new AvatarStore();
const dialogStore = new DialogStore();
const rootStore = new RootStore(dialogStore);
const drawerStore = new DrawerStore();
const toastStore = new ToastStore();
const translationStore = new TranslationStore(rootStore);
const userStore = new UserStore(rootStore);
const postStore = new PostStore(rootStore, toastStore, userStore);

const store = {
  rootStore,
  avatarStore,
  dialogStore,
  drawerStore,
  postStore,
  toastStore,
  translationStore,
  userStore
}

export default store;