import { action, makeObservable, observable } from 'mobx';

import api from '../api';
import { uuid } from '../helpers';

// Dependencies
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  updateDoc,
  where
} from 'firebase/firestore';
import { getStorage, ref, updateMetadata, uploadBytes} from 'firebase/storage';

/**
 * Handle retrieval and creation of posts
 * 
 * @function PostStore
 */
export default class PostStore {
  lastPost = null;
  loadingPosts = false;
  posts = [];
  postAuthors = {};
  showPostForm = false;

  /**
   * Set up observable variables
   * 
   * @param {Function} rootStore
   * @param {Function} toastStore
   * @param {Function} userStore
   */
  constructor(rootStore, toastStore, userStore) {
    this.rootStore = rootStore;
    this.toastStore = toastStore;
    this.userStore = userStore;

    makeObservable(this, {
      lastPost: observable,
      loadingPosts: observable,
      posts: observable,
      postAuthors: observable,
      showPostForm: observable
    });
  }

  /**
   * Get a list of posts
   * 
   * @async
   * @function listPostsRest
   * @param {object} queryOptions
   * @returns {object}
   */
  listPostsRest = async queryOptions => {
    const response = await api.get('/posts', queryOptions);
    
    if (!response.ok) {
      throw new Error('Failed to list posts.');
    }

    return response.json();
  }

  /**
   * Get a single post
   * 
   * @async
   * @function getPostRest
   * @param {string} postId
   * @returns {object}
   */
  getPostRest = async postId => {
    const response = await api.get(`/posts/${postId}`);

    if (!response.ok) {
      throw new Error('Failed to get post.');
    }

    return response.json();
  }

  /**
   * Delete a post
   * 
   * @async
   * @function deletePost
   * @param {string} postId
   * @returns {object}
   */
  deletePost = async postId => {
    const response = await api.delete(`/posts/${postId}`);

    if (!response.ok) {
      throw new Error('Failed to get post.');
    }

    return response.json();
  }

  /**
   * Get list of posts.
   * 
   * @function listPosts
   * @param {integer} postLimit
   * @param {object} postsAfter 
   * @param {boolean} append
   * @param {string} uid - A user id. If this is present, only return that user’s posts.
   * @returns {array}
   */
  listPosts = async (postLimit = 20, postsAfter = '', append, uid) => {
    const { db } = this.rootStore;
    const { userData } = this.userStore;

    action(() => {
      this.loadingPosts = true;
    })();

    // Get posts for this user and all users
    // that this user follows
    let userGroup = [];

    if (uid) {
      userGroup.push(uid);
    } else {
      if (userData?.follows) {
        userGroup = userGroup.concat(userData.follows);
      }

      userGroup.push(userData.uid);
    }

    // Clear out the last post
    action(() => {
      this.lastPost = null;
    })();

    // Get the posts
    const q = query(
      collection(db, 'posts'),
      where('postedBy', 'in', userGroup),
      orderBy('createdDate', 'desc'),
      startAfter(postsAfter),
      limit(postLimit)
    );

    const postSnap = await getDocs(q);

    const posts = [];

    postSnap.forEach(post => {
      const postObj = post.data();
      postObj.postID = post.id;
      posts.push(postObj);
    });

    action(() => {
      if (!append) {
        this.posts = posts;
      } else {
        this.posts = this.posts.concat(posts);
      }

      // Set up pagination button
      this.lastPost = postSnap.docs[postSnap.docs.length - 1];
      this.loadingPosts = false;
    })();

    return posts;
  }

  /**
   * Get an individual post
   * 
   * @async
   * @function getPost
   * @param {string} postId
   * @returns {object}
   */
  getPost = async postId => {
    const { db } = this.rootStore;
    
    const postRef = doc(db, 'posts', postId);
    const postSnap = await getDoc(postRef);

    if (!postSnap.exists()) {
      this.rootStore.throwError(`Post ${postId} does not exist.`);
      return null;
    }

    const postObj = postSnap.data();
    postObj.postID = postId;

    action(() => {
      this.posts = [postObj];
    })();

    return postObj;
  }

  /**
   * Store a post author’s user data
   * 
   * @function storeAuthorData
   * @param {object} authorData
   */
  storeAuthorData = action(authorData => {
    this.postAuthors[authorData.uid] = authorData;
  })

  /**
   * Add a play
   * 
   * Note: Rather than just incrementing a count, the
   * playCount is an array of timestamps. This prevents
   * concurrent plays from overwriting each other’s count update.
   * 
   * @async
   * @function addPlay
   * @param {object} post
   * @returns {number}
   */
  addPlay = async post => {
    const { db } = this.rootStore;
    const timestamp = Date.now();
    let plays = [];

    if (post.plays) {
      plays = [...post.plays];
    }

    plays.push(timestamp);

    const data = { plays };
    const postRef = doc(db, 'posts', post.postID);

    try {
      await updateDoc(postRef, data);
      return plays;
    } catch(error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Like a post
   * 
   * @async
   * @function likePost
   * @param {object} post
   * @param {string} uid
   * @returns {boolean}
   */
  likePost = async (post, uid) => {
    const { db } = this.rootStore;
    let likedBy = [];

    if (post.likedBy) {
      likedBy = [...post.likedBy];
    }

    if (likedBy.indexOf(uid) === -1) {
      likedBy.push(uid);
    }

    const data = { likedBy };
    const postRef = doc(db, 'posts', post.postID);

    try {
      await updateDoc(postRef, data);
      return true;
    } catch (error) {
      this.rootStore.throwError('Failed to like post.');
      return false;
    }
  }

  /**
   * Unlike a post
   * 
   * @async
   * @function unlikePost
   * @param {object} post
   * @param {string} uid
   * @returns {boolean}
   */
   unlikePost = async (post, uid) => {
    const { db } = this.rootStore;
    let likedBy = [];

    if (post.likedBy) {
      likedBy = [...post.likedBy];
    }

    const index = likedBy.indexOf(uid);

    if (index > -1) {
      likedBy.splice(index, 1);
    }

    const data = { likedBy };
    const postRef = doc(db, 'posts', post.postID);

    try {
      await updateDoc(postRef, data);
      return true;
    } catch (error) {
      this.rootStore.throwError('Failed to unlike post.');
      return false;
    }
  }

  /**
   * Report a post
   * 
   * @async
   * @function reportPost
   * @param {object} post
   * @param {string} uid
   * @returns {boolean}
   */
  reportPost = action(async (post, uid) => {
    const { db } = this.rootStore;

    this.rootStore.startLoading('reportPost');

    const data = {
      postID: post.postID,
      reportedBy: uid,
      createdDate: new Date().getTime()
    };

    let reportData = [];

    if (post.reportedBy) {
      reportData = [...post.reportedBy];
    }

    if (reportData.indexOf(uid) === -1) {
      reportData.push(uid);
    }

    // Add the report
    try {
      await addDoc(collection(db, 'reports'), data);
    } catch(error) {
      this.rootStore.throwError('Failed to create report.');
    }

    // Add this user’s ID to the array of users who have reported the post
    const postRef = doc(db, 'posts', post.postID);

    try {
      await updateDoc(postRef, { reportedBy: reportData });
    } catch(error) {
      console.error(error);
      this.rootStore.throwError('Failed to mark post as reported.');
    }

    this.rootStore.finishLoading('reportPost');

    // Show a success message
    this.toastStore.showToast('Post reported', 'success');

    // Hide the post
    action(() => {
      post.reported = true;
    })();
  })

  /**
   * Initialize a new post
   * 
   * @function newPost
   */
  newPost = action(() => {
    this.showPostForm = true;
  })

  /**
   * Cancel a new post
   * 
   * @function cancelPost
   */
  cancelPost = action(() => {
    this.showPostForm = false;
  })

  /**
   * Submit a new post
   * 
   * @async
   * @function createPost
   * @param {File} preview
   * @param {string} uid
   * @param {object} data
   * @returns {boolean}
   */
  createPost = async (preview, uid, data) => {
    const { db } = this.rootStore;

    const filename = `${uuid()}.mp3`;
    const storage = getStorage();
    const storageRef = ref(storage, `${uid}/p/${filename}`);

    const success = await uploadBytes(storageRef, preview)
      .then(async snapshot => {
        const payload = {
          audioURL: snapshot.metadata.fullPath,
          createdDate: Timestamp.fromDate(new Date()),
          hashtags: data.hashtags || null,
          mentions: data.mentions || null,
          likedBy: [],
          postedBy: uid,
          text: data.text || null,
          voteAnswer: data.voteAnswer || null
        };

        // TODO: Look into caching
        // Storage quota was over limit when I started this
        try {
          await updateMetadata(storageRef, { cacheControl: 'public,max-age=604800' });
        } catch (error) {
          console.error(error);
          this.rootStore.throwError('Failed to update post metadata.');
          return false;
        }

        try {
          await addDoc(collection(db, 'posts'), payload);
          return true;
        } catch (error) {
          console.error(error);
          this.rootStore.throwError('Failed to create post.');
          return false;
        }
      })

    return success;
  }

  /**
   * Vote on a post poll
   * 
   * @async
   * @function votePost
   * @param {object} post
   * @param {string} vote
   * @returns boolean
   */
  votePost = async (post, vote) => {
    const { db } = this.rootStore;

    let votes = [];

    if (post.votes) {
      votes = [...post.votes];
    }

    votes.push(vote);

    const data = { votes };
    const postRef = doc(db, 'posts', post.postID);

    try {
      await updateDoc(postRef, data);
      return true;
    } catch (error) {
      this.rootStore.throwError('Failed to vote on post.');
      return false;
    }
  }
}
