import React from 'react';

/**
 * Renders a checkbox component
 * 
 * @param {object} props
 * @property {string} [className]
 * @property {boolean} [defaultChecked]
 * @property {boolean} [disabled]
 * @property {string} errorMsg
 * @property {string} id
 * @property {string} label
 * @property {string} name
 * @property {Function} onChange
 * @property {string} value
 * @returns {Function}
 */
const Checkbox = props => {
  const {
    className,
    defaultChecked,
    disabled,
    errorMsg,
    id,
    label,
    name,
    onChange,
    required,
    value
  } = props;

  return (
    <div className={`cbox${className ? ` ${className}` : ''}`}>
      <input
        aria-required={required}
        checked={defaultChecked}
        className="cbox__input"
        data-errormsg={errorMsg || null}
        disabled={disabled}
        id={id}
        onChange={e => onChange(e)}
        name={name}
        required={required}
        type="checkbox"
        value={value || true}
      />

      <label 
        className="cbox__lbl"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

export default Checkbox;
