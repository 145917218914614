import { action, makeObservable, observable } from 'mobx';
import { api } from '../config';

/**
 * Handle avatar URL storage to prevent unnecessary calls to Firebase
 * 
 * @function AvatarStore
 */
export default class AvatarStore {
  userAvatars = {};

  /**
   * Set up observable variables
   */
  constructor() {
    makeObservable(this, {
      userAvatars: observable
    });
  }

  /**
   * Store a user’s avatar URL
   * 
   * @function storeAvatarUrl
   * @param {string} username
   * @param {string} url
   */
  storeAvatarUrl = action((username, url) => {
    if (!this.userAvatars[username] || this.userAvatars[username] !== url) {
      this.userAvatars[username] = url;
    }
  })

  setUserData = action(data => {
    this.user = data;
  })
}