import React from 'react';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

/**
 * Renders a page not found view
 * 
 * @param {object} props
 * @returns {Function}
 */
const Error404 = props => {
  // Get values from props
  const { translation } = props;

  return (
    <main
      id="main-content"
      className="page wrap"
      tabIndex="0"
      aria-labelledby="page-title"
    >
      <div className="panel panel--sm panel--solo">
        <h1 id="page-title" className="panel__title">
          {translation.heading}
        </h1>

        <p>{translation.body}</p>
      </div>
    </main>
  )
}

export default withStore(translate(Error404, 'Error404'));
