import React from "react";
import { observer } from 'mobx-react-lite';

// Store
import { withStore } from '../../stores/RootStore';

/**
 * Renders a post vote component
 *
 * @param {array} options
 * @param {string} title
 * @returns {Function}
 */
const PostVote = observer(props => {
  // Get values from props
  const { newPost, onSelect, options, post, store, title } = props;
  const { postStore } = store;

  // Check if the user has already voted
  let previousVote;

  if (!newPost) {
    previousVote = localStorage.getItem(`voted_${post.postID}`);
  }

  // Set up state
  const [selected, setSelected] = React.useState(previousVote?.length ? +previousVote : null);
  const [votes, setVotes] = React.useState(post?.votes || []);
  const [voting, setVoting] = React.useState(false);

  /**
   * Cast a vote
   * 
   * @async
   * @function handleVote
   * @param {number} selection
   */
  const handleVote = async selection => {
    if (newPost && onSelect) {
      onSelect(selection);
    }

    setSelected(selection);


    if (previousVote || newPost) {
      return;
    }

    setVoting(true);

    await postStore.votePost(post, selection);
    
    let updatedVotes = [];

    if (votes) {
      updatedVotes = [...votes];
    }


    updatedVotes.push(selection);

    setVotes(updatedVotes);
    setVoting(false);

    // TODO: Make this more secure
    localStorage.setItem(`voted_${post.postID}`, selection);
  }
 
  return (
    <section
      className={`vote${
        voting ? ' vote--voting' : ''
      }`}
    >
      {newPost &&
        <h4 className="vote__title">{title}</h4>
      }

      {!newPost &&
        <h4 className="meta">{title}</h4>
      }

      <ul className="vote__options">
        {options.map((option, i) =>
          <li key={i}>
            <button
              className="vote__btn btn btn--ghost"
              data-option={i}
              onClick={() => handleVote(i)}
              disabled={(!newPost && selected !== null && selected !== i) || (newPost && selected !== null && selected !== i)}
            >
              <span className="vote__text">{option}</span>

              {!newPost &&
                <>
                  {votes && votes.filter(vote => vote === i).length}
                  {!votes && '0'}
                </>
              }
            </button>
          </li>
        )}
      </ul>

      {/* TODO: Add actual translations for this */}
      {selected !== null && post && post.voteAnswer?.toString() &&
        <span className="vote__result">
          {selected == post.voteAnswer ? 'You’re right!' : 'Sorry, you’re wrong and dumb.'}
        </span>
      }
    </section>
  )
})
 
export default withStore(PostVote);
