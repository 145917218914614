import React from 'react';

/**
 * Renders a load more button that can be used with endless scrolling
 * 
 * @param {object} props
 * @returns {Function}
 */
const LoadMore = props => {
  // Get values from props
  const { infiniteScroll, onClick, text } = props;

  // Create refs
  const button = React.useRef(null);

  // Scroll throttle holder
  let throttle = null;

  // Main useEffect loop
  React.useEffect(() => {
    // Listen for scroll
    if (infiniteScroll) {
      window.addEventListener('scroll', throttleScroll, true);
    }

    // Remove the scroll listener on cleanup
    return () => {
      window.removeEventListener('scroll', throttleScroll, true);
    }
  }, []);

  /**
   * Throttle scroll events
   * @function throttleScroll
   */
  const throttleScroll = () => {
    if (throttle) {
      clearTimeout(throttle);
    }
    
    throttle = setTimeout(() => {
      if (isVisible() && onClick) {
        onClick();
      }
    }, 100);
  }

  /**
   * Determine if the button is within the viewport
   * 
   * @function isVisible
   * @returns {boolean}
   */
  const isVisible = () => {
    const bounds = button.current?.getBoundingClientRect();
    return bounds?.top <= window.innerHeight * 2;
  }

  return (
    <button
      className={`btn btn--pill${
        infiniteScroll ? ' btn--hidden' : ''
      }`}
      onClick={onClick || null}
      ref={button}
    >
      {text}
    </button>
  )
}

export default LoadMore;
