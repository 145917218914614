/**
 * Check the validity of form fields
 * 
 * @exports
 * @function checkValidity
 * @param {event} e
 * @param {Function} onValid
 * @param {Function} onInvalid
 */
export function checkValidity(e, onValid, onInvalid) {
  if (e) {
    e.preventDefault();
  }

  const form = e.target;
  const existingErrorMsg = form.querySelector('.form__error');

  // Mark the form as submitted
  form.classList.add('form--submitted');

  // Check for invalid fields
  const invalidFields = form.querySelectorAll('input[required]:invalid, select[required]:invalid, textarea[required]:invalid, input[required][aria-invalid="true"], select[aria-invalid="true"');

  // Remove existing error messages
  if (existingErrorMsg) {
    existingErrorMsg.parentNode.removeChild(existingErrorMsg);
  }

  if (!invalidFields.length) {
    // If everything is valid proceed
    if (onValid) {
      onValid(e);
    }
  } else {
    // Create a static list of invalid fields and direct focus to it.
    // Use this method instead of aria-live or role=alert. To use this
    // feature, all form inputs MUST have a valid label associated.
    const errorMsg = document.createElement('div');
    errorMsg.className = 'form__error';
    errorMsg.setAttribute('tabIndex', '-1');

    const errorMsgNote = document.createElement('p');
    errorMsgNote.innerText = 'Sorry, your form contained errors. Please correct the problems before submitting.';

    const errorMsgList = document.createElement('ul');

    invalidFields.forEach(field => {
      const errorMsgListItem = document.createElement('li');
      const label = form.querySelector(`[for="${field.id}"]`);

      errorMsgListItem.innerText = field.getAttribute('data-errormsg') || label.innerText;

      errorMsgList.appendChild(errorMsgListItem);
    });

    errorMsg.appendChild(errorMsgNote)
    errorMsg.appendChild(errorMsgList);

    const fields = form.querySelector('.form__fields:first-of-type');
    const fieldsParent = fields.parentNode;

    fieldsParent.insertBefore(errorMsg, fields);
    errorMsg.focus();

    if (onInvalid) {
      onInvalid(e);
    }
  }
}

/**
 * Selector for any HTML element that can receive keyboard focus
 * 
 * @exports
 */
export const focusable = "a[href]:not([tabindex='-1']),area[href]:not([tabindex='-1']),\
 input:not([disabled]):not([tabindex='-1']),select:not([disabled]):not([tabindex='-1']),\
 textarea:not([disabled]):not([tabindex='-1']),button:not([disabled]):not([tabindex='-1']),\
 iframe:not([tabindex='-1']),[tabindex]:not([tabindex='-1'])";

/**
 * Takes an object and returns it as URL query parameters
 * 
 * @exports
 * @function formatQuery
 * @param {object} query
 * @returns string
 */
export const formatQuery = query => {
  let queryString = '';

  for(let key in query) {
    if (!queryString) {
      // If this is the first key, add the ?
      queryString += '?'
    } else {
      // Otherwise, add an &
      queryString += '&'
    }

    queryString += `${key}=${query[key]}`;
  }

  return queryString;
}

/**
 * Get a given URL segment
 * 
 * @exports
 * @function getUrlSegment
 * @param {integer} segment
 * @param {pathname} [pathname]
 * @returns {string}
 */
export function getUrlSegment(segment, pathname) {
  if (!pathname) {
    pathname = window.location.pathname;
  }

  let pathArray = pathname.split('/');

  if (pathArray[0] === '') {
    pathArray.splice(0, 1);
  }

  return pathArray[segment - 1];
}

/**
 * Check if a given element is a descendant of a given parent
 * 
 * @exports
 * @function isDescendant
 * @param {object} parent
 * @param {object} child
 * @returns {boolean}
 */
export function isDescendant(parent, child) {
  let node = child.parentNode;

  while (node !== null) {
    if (node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

/**
 * Convert sections to mm:ss
 * 
 * @exports
 * @function parseSeconds
 * @param {number} seconds 
 * @returns {string}
 */
export function parseSeconds(seconds) {
  if (seconds === 0) {
    return '00:00';
  } else {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  }
}

/**
 * Create a UUID.
 * 
 * @exports
 * @function uuid
 * @returns {string}
 */
 export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


// TEMP: Probably won’t need this because I hate Firestore’s rest API
// const getFirestorePropType = value => {
//   const props = {
//     arrayValue: 1,
//     bytesValue: 1,
//     booleanValue: 1,
//     doubleValue: 1,
//     geoPointValue: 1,
//     integerValue: 1,
//     mapValue: 1,
//     nullValue: 1,
//     referenceValue: 1,
//     stringValue: 1,
//     timestampValue: 1
//   };

//   return Object.keys(value).find(k => props[k] === 1);
// }

// export const parseFirestoreData = data => {
//   let value = data;

//   const propType = getFirestorePropType(value);

//   switch(propType) {
//     case 'doubleValue':
//     case 'integerValue':
//       value = Number(value[propType]);
//       break;

//     case 'arrayValue':
//       value = ((value[propType] && value[propType].values) || []).map(v => parseFirestoreData(v));
//       break;
    
//     case 'mapValue':
//       value = parseFirestoreData((value[propType] && value[propType].fields) || {});
//       break;

//     case 'geoPointValue':
//       value = { latitude: 0, longitude: 0, ...value[propType]};
//       break;

//     default:
//       value = value[propType];
//   }

//   return value;
// }