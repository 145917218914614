import React from 'react';

// Dependencies
import { MediaRecorder } from 'extendable-media-recorder';
import Icon from 'alp-icons';

// Config
import { config } from '../../config';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { parseSeconds } from '../../helpers';

// Components
import AudioPlayer from './AudioPlayer';
import AudioVisualizer from './AudioVisualizer';
import PostVote from './PostVote';

/**
 * Renders an audio recorder
 * 
 * @param {object} props
 * @returns {Function}
 */
const AudioRecorder = props => {
  // Get values from props
  const { queryClient, store, translation } = props;
  const { postStore, userStore } = store;
  const { uid } = userStore.userData;

  // Set up state
  const [currentTime, setCurrentTime] = React.useState(0);
  const [mediaRecorder, setMediaRecorder] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [recording, setRecording] = React.useState(false);
  const [stream, setStream] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [voteAnswer, setVoteAnswer] = React.useState(null);
  const [voteSetup, setVoteSetup] = React.useState(false);

  // Create refs
  const timeLimit = React.useRef(null);
  const timeInterval = React.useRef(null);
  const textField = React.createRef();

  // Variables needed for the media recorder
  const chunkArr = [];
  let audioStream = null;

  // Main useEffect loop
  React.useEffect(async () => {
    // Set up the recorder
    audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setStream(audioStream);

    if (!mediaRecorder) {
      const recorder = new MediaRecorder(audioStream, { mimeType: 'audio/wav' });

      recorder.ondataavailable = e => {
        timerCleanup();
  
        chunkArr.push(e.data);
  
        const previewBlob = new Blob(chunkArr, { type: chunkArr[0].type });
  
        setCurrentTime(0);
        setPreview(previewBlob);
        setRecording(false);
      }

      setMediaRecorder(recorder);
    }
  }, []);

  // Cleanup useEffect loop
  React.useEffect(() => {
    return () => {
      // Clear intervals and timeouts
      timerCleanup();

      // Stop the media recorder
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
      }

      // Stop all streams
      if (audioStream) {
        audioStream.getTracks().forEach(track => {
          track.stop();
        })
      }

      setMediaRecorder(null);
      audioStream = null;
    }
  }, []);

  /**
   * Start and stop recording audio
   * 
   * @function handleRecord
   */
  const handleRecord = () => {
    timerCleanup();

    if (!recording) {
      // Start recording
      mediaRecorder.start();

      chunkArr.length = 0;

      setPreview(null);

      timeLimit.current = setTimeout(() => {
        mediaRecorder.stop();
      }, config.maxRecordingLength);

      timeInterval.current = setInterval(() => {
        setCurrentTime(prevTime => prevTime + 1);
      }, 1000);

      setRecording(true);
    } else {
      // Finish recording
      if (mediaRecorder?.state === 'recording') {
        mediaRecorder.stop();
      }

      setRecording(false);
    }
  }

  /**
   * Clean up intervals and timeouts
   * 
   * @function timerCleanup
   */
  const timerCleanup = () => {
    clearTimeout(timeLimit.current);
    clearInterval(timeInterval.current);
  }

  /**
   * Check text for keywords
   * 
   * @function handleText
   * @param {event} e
   */
  const handleText = e => {
    const { value } = e.target;

    if (value?.toLowerCase().indexOf('#cheezortease') > -1 && !voteSetup) {
      setVoteSetup(true)
    }
  }

  /**
   * Handle selection of the correct answer for a vote
   * 
   * @function handleVoteAnswer
   * @param {number} selection
   */
  const handleVoteAnswer = selection => {
    setVoteAnswer(selection);
  }

  /**
   * Submit the recorded audio
   * 
   * @async
   * @function handleSubmit
   */
  const handleSubmit = async () => {
    const text = textField.current.value;

    setSubmitting(true);

    let data = {};

    // Parse hashtags and mentions
    if (text) {
      const hashtags = [...new Set(text.match(/\B\#\w\w+\b/g))];
      const mentions = [...new Set(text.match(/\B\@\w\w+\b/g))];

      hashtags.forEach((tag, i) => {
        hashtags[i] = tag.replace('#', '').toLowerCase();
      });

      mentions.forEach((mention, i) => {
        mentions[i] = mention.replace('@', '').toLowerCase();
      });

      data = {
        hashtags,
        mentions,
        text
      };
    }

    if (voteAnswer !== null) {
      data.voteAnswer = voteAnswer.toString();
    }

    const posted = await postStore.createPost(preview, uid, data);

    if (posted) {
      // Close the post modal
      postStore.cancelPost();
      queryClient.invalidateQueries('listPosts');
    } else {
      setSubmitting(false);
    }
  }

  return (
    <section className="record">
      <header className="record__head">
        <h3 className="meta">{translation.heading}</h3>

        <button
          className="btn btn--ghost icon--sm"
          onClick={postStore.cancelPost}
        >
          <Icon name="times" />
          <span className="meta">{translation.cancel}</span>
        </button>
      </header>

      <div className="record__controls">
        <button
          aria-describedby="record-hint"
          className={`record__btn btn btn--round${
            recording ? ' record__btn--recording' : ''
          }`}
          onClick={handleRecord}
          type="button"
        >
          {!preview &&
            <span className="meta" id="record-hint">
              {recording ? translation.stop_hint : translation.start_hint}
            </span>
          }
        </button>

        {!preview && window.AudioContext &&
          <AudioVisualizer
            stream={stream}
          />
        }

        {!preview &&
          <span className="record__time">
            {parseSeconds(currentTime)}/{parseSeconds(config.maxRecordingLength / 1000)}
          </span>
        }

        {preview &&
          <AudioPlayer
            {...props}
            audioUrl={window.URL.createObjectURL(preview)}
            local
            minimal
          />
        } 
      </div>

      <fieldset className="record__text">
        <label
          className="meta"
          htmlFor="post-text"
        >
          {translation.text.label}
        </label>

        <textarea
          id="post-text"
          onChange={e => handleText(e)}
          placeholder={translation.text.helper}
          ref={textField}
        />

        {voteSetup &&
          <PostVote
            newPost
            onSelect={handleVoteAnswer}
            options={['🧀', '🤪']}
            title={'Is this a Cheez or a Tease?'}
          />
        }
      </fieldset>

      <button
        aria-disabled={!preview}
        className="btn btn--full"
        disabled={!preview || submitting}
        onClick={handleSubmit}
        type="button"
      >
        {translation.submit}
      </button>

      {submitting &&
        <aside className="record__loading">
          <span className="loader--async">
            <span className="meta">{translation.submitting}</span>
          </span>
        </aside>
      }
    </section>
  )
}

export default withStore(translate(AudioRecorder, 'AudioRecorder'));
