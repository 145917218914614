import { action, makeObservable, observable, observe } from 'mobx';

// Helpers
import { uuid } from '../helpers';

// Dependencies
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { deleteObject, getStorage, ref, uploadBytes} from 'firebase/storage';

/**
 * Handle the logged in user
 * 
 * @function UserStore
 */
export default class UserStore {
  loggedIn = false;
  userAvatar = null;
  userData = null;

  /**
   * Add the RootStore for getting Firestore data
   * Set up observable variables
   * 
   * @param {Function} RootStore
   */
  constructor(RootStore) {
    this.rootStore = RootStore;

    makeObservable(this, {
      loggedIn: observable,
      userData: observable
    });
  }

  /**
   * Get the user’s profile data
   * 
   * @async
   * @function getUserData
   * @param {string} uid
   * @param {boolean} skipStore
   * @returns {object}
   */
  getUserData = async (uid, skipStore) => {
    const db = this.rootStore.db;

    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      this.rootStore.throwError(`User ${uid} does not exist.`);
      return false;
    }

    const userData = userSnap.data();
    userData.uid = uid;

    if (!skipStore) {
      action(() => {
        this.userData = userData;
      })();
    }

    return userData;
  }

  /**
   * Check if a username is taken
   * 
   * @async
   * @function getUsername
   * @param {string} name
   * @returns boolean
   */
  getUsername = async name => {
    const db = this.rootStore.db;

    const unameRef = doc(db, 'unames', name);
    const unameSnapshot = await getDoc(unameRef);

    if (!unameSnapshot.exists()) {
      return null;
    }

    return unameSnapshot.data();
  }

  /**
   * Sign up a user with email and password
   * 
   * @async
   * @function signUp
   * @param {object} payload
   * @returns {boolean}
   */
  signUp = async payload => {
    const db = this.rootStore.db;
    const { email, password, username } = payload;

    this.rootStore.startLoading('signUp');

    const auth = getAuth();

    const success = await createUserWithEmailAndPassword(auth, email, password)
      .then(async credentials => {

        // Set the username after creating the user
        const { user } = credentials;

        updateProfile(user, {
          username
        });

        // Add the user to the users collection
        const userData = {
          follows: [],
          uid: user.uid,
          uname: username
        };

        try {
          await setDoc(doc(db, 'users', user.uid), userData);
        } catch (error) {
          console.error('Failed to add user to users table.');
          console.error(error);
          return false;
        }

        // Add the username to the unames collection
        try {
          await setDoc(doc(db, 'unames', username.toLowerCase()), {
            user: user.uid
          });
        } catch (error) {
          console.error('Failed to add username to usernames table.');
          console.error(error);
          return false;
        }

        try {
          sendEmailVerification(auth.currentUser)
            .then(res => {
              console.log(res);
            });
        } catch (error) {
          console.error('Failed to send verification email.');
          console.error(error);
          return false;
        }

        this.rootStore.finishLoading('signUp');

        await this.getUserData(user.uid);

        return true;
      });

    return success;
  }

  /**
   * Sign in a user with email and password
   * 
   * @async
   * @function signIn
   * @param {object} payload 
   * @returns {boolean}
   */
  signIn = async payload => {
    const { email, password } = payload;

    this.rootStore.startLoading('signIn');

    const auth = getAuth();

    const success = await signInWithEmailAndPassword(auth, email, password)
      .then(async credentials => {
        this.rootStore.finishLoading('signIn');

        // Get the signed in user’s data
        await this.getUserData(credentials.user.uid);

        return true;
      })
      .catch(() => {
        this.rootStore.throwError('Invalid email or password.');
        return false;
      });

    return success;
  }

  /**
   * Sign out the active user
   * 
   * @async
   * @function signOut
   * @returns {boolean}
   */
  signOut = async () => {
    this.rootStore.startLoading('signOut');

    const auth = getAuth();

    const success = await signOut(auth)
      .then(action(() => {
        this.loggedIn = false;
        this.userData = null;

        this.rootStore.finishLoading('signOut');
        return true;
      }))
      .catch(() => {
        this.rootStore.throwError('Error signing out.');
        return false;
      });

    return success;
  }

  /**
   * Follow a user
   * 
   * @async
   * @function followUser
   * @param {string} uid
   * @returns boolean
   */
   followUser = async uid => {
    const db = this.rootStore.db;

    this.rootStore.startLoading('followUser');

    const follows = [...this.userData.follows];
    const index = follows.indexOf(uid);

    if (index === -1) {
      follows.push(uid);
    } else {
      this.rootStore.finishLoading('followUser');
      return;
    }

    const userRef = doc(db, 'users', this.userData.uid);

    try {
      await updateDoc(userRef, { follows });

      action(() => {
        this.userData.follows = follows;
      })();

      this.rootStore.finishLoading('followUser');
      
      return true;
    } catch(error) {
      console.error('Failed to follow user.');
      console.error(error);
      return false;
    }
  }

  /**
   * Unfollow a user
   * 
   * @async
   * @function unfollowUser
   * @param {string} uid
   * @returns boolean
   */
  unfollowUser = async uid => {
    const db = this.rootStore.db;

    this.rootStore.startLoading('unfollowUser');

    const follows = [...this.userData.follows];
    const index = follows.indexOf(uid);

    if (index > -1) {
      follows.splice(index, 1);
    } else {
      this.rootStore.finishLoading('followUser');
      return;
    }

    const userRef = doc(db, 'users', this.userData.uid);

    try {
      await updateDoc(userRef, { follows });

      action(() => {
        this.userData.follows = follows;
      })();
      
      this.rootStore.finishLoading('unfollowUser');

      return true;
    } catch(error) {
      console.error('Failed to unfollow user.');
      console.error(error);
      return false;
    }
  }

  /**
   * Update a user’s avatar
   * 
   * @async
   * @function updateAvatar
   * @param {object} image
   * @returns {boolean}
   */
  updateAvatar = async image => {
    const db = this.rootStore.db;

    this.rootStore.startLoading('updateAvatar');

    const filename = `${uuid()}.${image.type.replace('image/', '')}`;
    const storage = getStorage();
    const storageRef = ref(storage, `${this.userData.uid}/a/${filename}`);

    const success = await uploadBytes(storageRef, image)
      .then(async snapshot => {
        const photoURL = snapshot.metadata.fullPath;

        // Delete the user’s old avatar
        const oldImageRef = ref(storage, this.userData.photoURL);

        try {
          await deleteObject(oldImageRef);
        } catch(error) {
          console.error('Failed to delete user’s old avatar.');
          console.error(error);
        }

        // Store the new avatar URL in the user’s data
        const userRef = doc(db, 'users', this.userData.uid);

        try {
          await updateDoc(userRef, { photoURL });
          this.rootStore.finishLoading('updateAvatar');

          // Update the avatar URL in store
          const userData = {...this.userData};
          userData.photoURL = photoURL;

          action(() => {
            this.userData = userData
          })();

          return true;
        } catch(error) {
          console.error('Failed to update user’s avatar  URL.');
          console.error(error);
          return false;
        }
      })
      .catch(error => {
        console.error(error);
        this.rootStore.throwError('Failed to upload image.');
        return false;
      })

    return success;
  }
}
