import React from 'react';

// Translation
import translate from '../Translate';

// Dependencies
import Icon from 'alp-icons';

/**
 * Renders an unauthorized view
 * 
 * @param {object} props
 * @returns {Function}
 */
const ErrorUnauthorized = props => {
  // Get values from props
  const { translation } = props;

  return (
    <main
      id="main-content"
      className="page wrap"
      tabIndex="0"
      aria-labelledby="page-title"
    >
      <div className="panel panel--sm panel--solo">
        <i className="panel__icon">
          <Icon name="lock" />
        </i>

        <h1 id="page-title" className="panel__title">
          {translation.heading}
        </h1>

        <p>{translation.body}</p>
      </div>
    </main>
  )
}

export default translate(ErrorUnauthorized, 'ErrorUnauthorized');