import React from 'react';


/**
 * Renders the terms of service page
 * 
 * @param {object} props
 * @returns {Function}
 */
const Terms = props => {
  // Get values from props
  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo panel--longform">
          <h2
            className="panel__title"
            id="page-title"
          >
            Terms and Conditions
          </h2>

          <p>These Terms and Conditions apply to you when you use Cheezr (the App).</p>

          <p>By downloading or using the App, these terms will automatically apply to you. You should make sure, therefore, that you read them carefully before using the App. You are not permitted to copy or modify the App, any part of the App, or our trademarks in any way. You are not permitted to attempt to extract the source code of the App. You are not permitted translate the App into other languages, or make derivative versions. The App itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, belong to Cheezr.</p>

          <p>Cheezr is committed to ensuring that the App is as useful and efficient as possible. For that reason, we reserve the right to make changes to the App or to charge for its services, at any time and for any reason. We will never charge you for the App or its services without making it very clear to you exactly what you are paying for.</p>

          <p>The App stores and processes personal data that you have provided in order to provide our Service. It is your responsibility to keep your access to the App secure. We therefore recommend that you do not jailbreak or root your device, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features, and it could mean that the App won’t work properly or at all.</p>

          <p>There are certain things that Cheezr will not take responsibility for. Certain functions of the App require the App to have an active internet connection. Regardless of the connection type, Cheezr cannot take responsibility for the App not working at full functionality if you do not have access a reliable connection or if you are using an unsupported device, operating system, or browser.</p>

          <p>If you use the App on a mobile network, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the App, or other third party charges. In using the App, you accept responsibility for any such charges, including roaming data charges if you use the App outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you use the App, please be aware that we assume that you have received permission from the bill payer for using the App.</p>

          <p>Along the same lines, Cheezr cannot always take responsibility for the way you use the App. If your device turns off or loses its charge and you cannot turn it on to avail the Service, Cheezr cannot accept responsibility.</p>

          <p>With respect to Cheezr’s responsibility for your use of the app, when you use the app, it iss important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Cheezr accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the App.</p>

          <p>At some point, we may wish to update the App. The App is currently available on the Web, Android, and iOS. The requirements for each system (and for any additional systems we decide to extend the availability of the app to) may change, and you may need to download the updates if you want to keep using the App. Cheezr does not promise that it will always update the App so that it is relevant to you and/or works with the device, browser or operating system version that you are using. However, you agree to always accept updates to the application when offered to you. We may also wish to stop providing the App, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the App, and (if needed) delete it from your device.</p>

          <h3>Changes to This Terms and Conditions</h3>

          <p>We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.</p>

          <p>These terms and conditions are effective as of March 10, 2021.</p>

          <h3>Contact Us</h3>

          <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at admin@cheezr.app.</p>
        </div>
    </main>
  )
}

export default Terms;
