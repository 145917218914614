import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Dependencies
import Icon from 'alp-icons'

// Routes
import routes from '../../routes';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

/**
 * Renders the main app header
 * 
 * @param {object} props
 * @returns {Function}
 */
const Nav = props => {
  // Get values from props
  const { store, translation } = props;
  const { userStore } = store;
  const { loggedIn, userData } = userStore;

  // Set up location tracking
  const { pathname } = useLocation();

  // Set up nav links
  const navLinks = [{
    icon: 'home',
    route: routes.index,
    title: translation.index
  }, {
    icon: 'magnifyingGlass',
    route: routes.search,
    title: translation.search
  }, {
    icon: 'person',
    route: `/${userData?.uname}`,
    title: translation.profile
  }, {
    icon: 'settings',
    route: routes.settings,
    title: translation.settings
  }];

  if (!loggedIn) {
    navLinks.length = 2;
  }

  /**
   * Show or hide the navigation menu
   * 
   * @function handleNav
   */
  const handleNav = () => {
    document.body.classList.toggle('nav-visible');
  }

  return (
    <nav className="nav nav--main">
      <h2 className="meta">{translation.main_nav}</h2>

      <ul className="nav__list">
        {navLinks.map((link, i) =>
          <li key={i}>
            <Link
              className={`nav__link${
                pathname === link.route ? ' nav__link--active' : ''
              }`}
              to={link.route}
              onClick={handleNav}
            >
              <Icon name={link.icon} />
              {link.title}
            </Link>
          </li>
        )}

        <li>
          <button
            className="nav__link"
            onClick={() => {
              handleNav();
              window.history.pushState(null, null, '/sign-in');
              userStore.signOut();
            }}
            type="button"
          >
            <Icon name="exit" />
            {translation.sign_out}
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default withStore(translate(Nav, 'Nav'));