import React from 'react';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { focusable } from '../../helpers';

// Dependencies
import Icon from 'alp-icons';

/**
 * Renders a slide-out drawer component
 * 
 * @param {object} props
 * @property {string} [className]
 * @property {Function} [component]
 * @property {string} [iframeUrl]
 * @property {Function} onClose
 * @property {string} [title]
 * @returns {Function}
 */
const Drawer = props => {
  // Get values from props
  const {
    className,
    component,
    iframeUrl,
    onClose,
    store,
    title,
    translation
  } = props;
  const { DrawerStore } = store;

  // Set up state
  const [exiting, setExiting] = React.useState(false);

  // Create refs
  const drawerRoot = React.createRef();

  // Set up keyboard focus elements
  let focusableElements;
  let firstFocusableElement;
  let lastFocusableElement;

  // Main useEffect loop
  React.useEffect(() => {
    if (!focusableElements) {
      focusableElements = drawerRoot.current.querySelectorAll(focusable);
      firstFocusableElement = focusableElements[0];
      lastFocusableElement = focusableElements[focusableElements.length - 1];
    }
  }, [exiting]);

  /**
   * Handle keyboard events
   * 
   * @function handleKeyDown
   * @param {event} e
   */
  const handleKeyDown = e => {
    const { key, target } = e;
    const ESCAPE = "Escape";
    const TAB = "Tab";
    const SHIFT = e.shiftKey;

    // Tab Key behavior
    if (key === TAB && !SHIFT) {
      // Wrap focus to the top of the drawer
      if (target === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    }

    // Shift + Tab Key behavior
    if (key === TAB && SHIFT) {
      // Wrap focus to the bottom of the drawer
      if (target === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement.focus();
      }
    }

    // Escape Key behavior
    if (key === ESCAPE) {
      handleClose();
    }
  }

  /**
   * Close the drawer
   * 
   * @function handleClose
   */
  const handleClose = () => {
    setExiting(true);

    if (onClose) {
      onClose();
    }

    // Wait for the slide-out animation to
    // complete before removing the drawer
    setTimeout(() => {
      DrawerStore.closeDrawer();
    }, 300);
  }

  return (
    <aside
      aria-labelledby="drawer-title"
      className={`drawer${
        className ? ` ${className}` : ''
      }${
        exiting ? ' drawer--exiting' : ''
      }`}
      id="drawer-content"
      onKeyDown={handleKeyDown}
      ref={drawerRoot}
    >
      <header
        className={`drawer__header${
          iframeUrl ? ' drawer__header--frame' : ''
        }`}
      >
        {title && (
          <h2
            className={iframeUrl ? 'meta' : 'drawer__title'}
            id="drawer-title"
          >
            {title}
          </h2>
        )}

        <button
          className={`drawer__close${
            iframeUrl ? ' drawer__close--frame' : ''
          }`}
          onClick={handleClose}
        >
          <Icon name="timesThin" />
          <span className="meta">{translation.close}</span>
        </button>
      </header>

      <div
        className={`drawer__content ${
          iframeUrl ? ' drawer__content--frame' : ''
        }`}
      >
        {iframeUrl && (
          <iframe
            className="drawer__frame"
            src={iframeUrl}
            title={title}
          />
        )}

        {component && component}
      </div>
    </aside>
  )
}

export default withStore(translate(Drawer, 'Drawer'));
