import React from 'react';

/**
 * Renders a tooltip component
 * 
 * @param {object} props
 * @property {boolean} [brief]
 * @property {string} content
 * @property {boolean} [down]
 * @property {boolean} [hideLabel]
 * @property {Function} [icon]
 * @property {string} id
 * @property {boolean} [left]
 * @property {boolean} [right]
 * @returns {Function}
 */
const Tooltip = props => {
  // Get values from props
  const {
    brief,
    content,
    down,
    hideLabel,
    icon,
    id,
    left,
    right
  } = props;
    

  return (
    <div
      className={`tooltip${
        brief ? ' tooltip--brief' : ''
      }${
        down ? ' tooltip--down' : ''
      }${
        left ? ' tooltip--left' : ''
      }${
        right ? ' tooltip--right' : ''
      }${
        hideLabel ? ' tooltip--no-label' : ''
      }`}
    >
      {!hideLabel &&
        <span
          className="tooltip__label"
          role="presentation"
          aria-hidden="true"
        >
          (?)
        </span>
      }

      {icon ? icon : ''}

      <div
        id={id}
        className="tooltip__body"
        role="tooltip"
      >
        {content}
      </div>
    </div>
  )
}

export default Tooltip;