import React from 'react';

/**
 * Renders an audio visualizer
 * 
 * @param {object} props
 * @returns {Function}
 */
const AudioVisualizer = props => {
  // Get values from props
  const { element, stream } = props;

  // Set up state
  const [rendered, setRendered] = React.useState(false);

  // Create refs
  const visualizer = React.createRef();

  // Main useEffect loop
  React.useEffect(() => {
    handleVisualizer();
  }, [element, stream]);

  /**
   * Create the audio visualization
   */
  const handleVisualizer = () => {
    const audioCtx = new window.AudioContext();
    let src;

    if (element) {
      src = audioCtx.createMediaElementSource(element);
    } else if (stream) {
      src = audioCtx.createMediaStreamSource(stream);
    } else {
      return;
    }

    const analyser = audioCtx.createAnalyser();

    src.connect(analyser);
    analyser.fftSize = 256;

    const canvas = visualizer.current;
    const canvasCtx = canvas.getContext('2d');

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    let barWidth = (canvas.width / bufferLength) * 2.5;
    let barHeight;

    const renderFrame = () => {
      requestAnimationFrame(renderFrame);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      analyser.getByteFrequencyData(dataArray);

      let offset = 0;

      for (var i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];

        canvasCtx.fillStyle = '#146bee';
        canvasCtx.fillRect(offset, canvas.height - barHeight, barWidth, barHeight);

        offset += barWidth + 1;
      }
    }

    renderFrame();
  }

  return (
    <canvas
      className="visualizer"
      ref={visualizer}
    />
  )
}

export default AudioVisualizer;
