import React from 'react';
import { action, get } from 'mobx';
import { observer } from 'mobx-react-lite';
import { QueryClient, QueryClientProvider } from 'react-query';

// Dependencies
import { initializeApp } from 'firebase/app';
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';

// Config
import { config } from '../config';

// Store
import { withStore } from '../stores/RootStore';

// Routes
import { protectedRoutes } from '../routes';

// Helpers
import { getUrlSegment } from '../helpers';

// Import common components
import AppRouter from './Router';
import AudioRecorder from './common/AudioRecorder';
import Dialog from './common/Dialog';
import Drawer from './common/Drawer';
import Toast from './common/Toast';

// Logo
import Logo from './ui/Logo';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

/**
 * Renders the main app component
 * 
 * @param {object} props
 * @returns {Function}
 */
const App = props => {
  // Get values from props
  const { store } = props;
  const {
    dialogStore,
    drawerStore,
    postStore,
    rootStore,
    toastStore,
    translationStore,
    userStore
  } = store;

  // Set up state
  const [render, setRender] = React.useState(false);
  const [translation, setTranslation] = React.useState(null);

  // Main useEffect hook
  React.useEffect(async () => {
    if (!translationStore.translation) {
      const translationContent = await translationStore.getTranslation();
      setTranslation(translationContent);
    }

    // Store the queryClient
    action(() => {
      rootStore.queryClient = queryClient;
    })();

    // Initialize Firebase
    const firebaseConfig = {
      apiKey: 'AIzaSyAnvZslCX3rSCKsodOyuhl8JLCg2vA3MrY',
      authDomain: 'cheezr-stage.firebaseapp.com',
      databaseURL: 'https://cheezr-stage.firebaseio.com',
      storageBucket: 'gs://cheezr-stage.appspot.com/',
      projectId: 'cheezr-stage'
    };

    let firebaseApp = null;

    if (!firebaseApp) {
      firebaseApp = initializeApp(firebaseConfig);
    }

    // Initialize the DB
    if (!rootStore.db) {
      rootStore.initDB(firebaseApp);
    }

    // Initialize Firebase auth and redirect based on the
    // current user’s login status
    const auth = getAuth(firebaseApp);

    onAuthStateChanged(auth, async user => {
      if (user) {
        const idToken = await user.auth.currentUser.getIdToken(true);

        localStorage.setItem('idToken', idToken);

        // If the user is logged, in mark it and get additional user data
        action(() => {
          userStore.idToken = idToken;
          userStore.loggedIn = true;
        })();
        
        await userStore.getUserData(user.uid);

        setRender(true);
      } else if (protectedRoutes.indexOf(getUrlSegment(1)) > -1 || getUrlSegment(1) === '') {
        // Otherwise if the user is not logged in and they are not on
        // the sign on or sign up page, mark as logged out and redirect
        // to the sign in page
        action(() => {
          userStore.loggedIn = false;
          userStore.userData = null;
        })();

        window.location.href = '/sign-in';

        setRender(true);
      } else {
        // Otherwise, just enable render
        setRender(true);
      }
    });
  }, []);

  if (render) {
    return (
      <QueryClientProvider client={queryClient}>
        <a
          className="btn btn--skip"
          href="#main-content"
        >
          {translation.App.skip_to_main}
        </a>

        <div className="viewport">
          <AppRouter
            {...props}
            loggedIn={userStore.loggedIn}
            userData={userStore.userData}
          />

          {rootStore.recordingSupported && userStore.loggedIn && postStore.showPostForm &&
            <AudioRecorder
              {...props}
              queryClient={queryClient}
            />
          }
          
          {drawerStore.drawer &&
            <Drawer
              className={drawerStore.drawerClassName}
              component={drawerStore.drawerComponent}
              iframeUrl={drawerStore.drawerUrl}
              onClose={drawerStore.drawerOnClose}
              title={drawerStore.drawerTitle}
            />
          }

          {dialogStore.dialog &&
            <Dialog
              content={dialogStore.dialogContent}
              onCancel={dialogStore.dialogOnCancel}
              onChange={dialogStore.dialogOnChange}
              onConfirm={dialogStore.dialogOnConfirm}
              onTimeout={dialogStore.dialogOnTimeout}
              options={dialogStore.dialogOptions}
              timeout={dialogStore.dialogTimeout}
              type={dialogStore.dialogType}
            />
          }

          {toastStore.toast && 
            <Toast
              content={toastStore.toast}
              onDismiss={toastStore.toastOnDismiss}
              timeout={toastStore.toastTimeout}
              type={toastStore.toastType}
            />
          }

          {rootStore.loading &&
            <aside className="loader">
              <div className="loader__msg">
                <Logo />
                <span className="meta">Loading…</span>
              </div>
            </aside>
          }
          
        </div>
      </QueryClientProvider>
    )
  }

  return null;
}

export default withStore(observer(App));
