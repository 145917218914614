import { action, makeObservable, observable } from 'mobx';

/**
 * Handle sliding drawers
 * 
 * @function DrawerStore
 */
export default class DrawerStore {
  drawer = false;
  drawerClassName = null;
  drawerComponent = null;
  drawerOnClose = null;
  drawerTitle = null;
  drawerUrl = null;

  /**
   * Set up observable variables
   */
  constructor() {
    makeObservable(this, {
      drawer: observable,
      drawerClassName: observable,
      drawerComponent: observable,
      drawerOnClose: observable,
      drawerTitle: observable,
      drawerUrl: observable
    });
  }

  /**
   * Show a slide-out drawer
   * 
   * @function showDrawer
   * @param {object} options
   * @param {string} [options.className]
   * @param {Function} [options.component] Required if iframeUrl is not provided
   * @param {string} [options.iframeUrl] Required if component is not provided
   * @param {Function} [options.onClose]
   * @param {string} [options.title]
   */
  showDrawer = action(options => {
    const {
      className,
      component,
      iframeUrl,
      onClose,
      title
    } = options;

    className && (this.drawerClassName = className);
    component && (this.drawerComponent = component);
    onClose && (this.drawerOnClose = onClose);
    title && (this.drawerTitle = title);

    // Components supersede iframe URLs
    iframeUrl && !component && (this.drawerUrl = iframeUrl);

    this.drawer = true;
  })

  /**
   * Close the drawer
   * 
   * @function closeDrawer
   */
  closeDrawer = action(() => {
    this.drawer = false;
    this.drawerClassName = null;
    this.drawerComponent = null;
    this.drawerOnClose = null;
    this.drawerTitle = null;
    this.drawerUrl = null;

    // Focus the main content element
    const main = document.querySelector('#main-content');

    if (main) {
      main.focus();
    }
  })
}