import { config } from './config';
import { formatQuery } from './helpers';

const headers = { 'Authorization': `Bearer ${localStorage.getItem('idToken')}` };

const buildUrl = (path, query) => {
  return `${config.apiUrl}${path}${formatQuery(query)}`;
};

const api = {
  /**
   * Make a GET call
   * 
   * @async
   * @function get
   * @param {string} path
   * @param {object} query
   */
  get: async (path, query) => {
    return await fetch(buildUrl(path, query), { headers })
      .catch(error => {
        console.error(error);
      });
  },

  /**
   * Make a DELETE call
   * 
   * @async
   * @function delete
   * @param {string} path
   * @param {object} query
   */
  delete: async (path, query) => {
    return await fetch(buildUrl(path, query), { method: 'DELETE', headers })
      .catch(error => {
        console.error(error);
      });
  }
}

export default api;
