import React from 'react';

/**
 * Renders the privacy policy page
 * 
 * @param {object} props
 * @returns {Function}
 */
const PrivacyPolicy = props => {
  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo panel--longform">
        <h2
          className="panel__title"
          id="page-title"
        >
          Privacy Policy
        </h2>

        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you use Cheezr (the App).</p>

        <h3>Personal information we collect</h3>

        <p>When you visit the App, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the App, we collect information about the individual pages or posts that you view, what websites or search terms referred you to the App, and information about how you interact with the App. We refer to this automatically-collected information as “Device Information.”</p>

        <p>For a better experience, while using the App, we may require you to provide us with certain personally identifiable information, including but not limited to your email address, audio recordings, your avatar. The information that we request will be retained by us and used as described in this privacy policy.</p>

        <h4>We collect Device Information using the following technologies:</h4>

        <ul>
          <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
          <li>“Log files” track actions occurring on the App, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
        </ul>

        <h3>How we use your personal information</h3>

        <p>We use the information that we collect to:</p>

        <ul>
          <li>Communicate with you;</li>
          <li>Help your friends find you on the App;</li>     
          <li>Screen posts for potential risk or fraud; and</li>
          <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
        </ul>

        <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our App (for example, by generating analytics about how our customers browse and interact with the App, and to identify any bugs).</p>

        <h3>Sharing your personal information</h3>

        <p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Firebase to store data and content—you can read more about Firebase here:https://firebase.google.com/support/privacy. We also use Google Analytics to help us understand how our customers use the App—you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>

        <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

        <h3>Do not track</h3>

        <p>Please note that we do not alter our App’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

        
        <h3>Your rights</h3>

        <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>

        <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the App), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>

        <h3>Data retention</h3>

        <p>When you use the App, we will maintain your Information for our records unless and until you ask us to delete this information.</p>

        <h3>Minors</h3>

        <p>The App is not intended for individuals under the age of 18.</p>

        <h3>Changes</h3>

        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

        <h3>Contact us</h3>

        <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at admin@cheezr.app</p>
      </div>
    </main>
  )
}

export default PrivacyPolicy;
