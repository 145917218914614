import { getDownloadURL, getStorage, ref } from 'firebase/storage';

/**
 * Get an asset’s URL from Firebase
 * 
 * @async
 * @function getAssetUrl
 * @param {string} path
 * @returns {string}
 */
export async function assetUrl(path) {
  const storage = getStorage();
  const imageRef = ref(storage, path);

  try {
    return await getDownloadURL(imageRef)
      .then(url => {
        return url;
      });
  } catch(error) {
    console.error(error);
  }
}