import React from 'react';
import { observer } from 'mobx-react-lite';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

/**
 * Renders the settings view
 * 
 * @param {object} props
 * @returns {Function}
 */
const Tags = observer(props => {
  // Get values from props
  const { translation } = props;

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <section className="page__primary">
        <h2
          id="page-title"
        >
          {translation.heading}
        </h2>
      </section>
    </main>
  )
})

export default withStore(translate(Tags, 'Tags'));
