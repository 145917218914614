export const config = {
  // apiUrl: 'http://localhost:8047/api/v1',
  apiUrl: 'https://cheezr-api.herokuapp.com/api/v1',
  domain: 'http://localhost:3047',
  // listPostLimit: 20,
  listPostLimit: 10,
  maxRecordingLength: 15000
};

// Flag different environments
if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
  config.domain = 'https://dev.cheezr.app';
}

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  config.apiUrl = 'https://firestore.googleapis.com/v1/projects/cheezr/databases/(default)/documents';
  config.domain = 'https://cheezr.app';
}
