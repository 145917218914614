import { action, makeObservable, observable } from 'mobx';

/**
 * Handle pop-up dialog messages
 * 
 * @function DialogStore
 */
export default class DialogStore {
  dialog = false;
  dialogContent = null;
  dialogOnCancel = null;
  dialogOnChange = null;
  dialogOnConfirm = null;
  dialogOnTimeout = null;
  dialogTimeout = null;
  dialogType = null;

  /**
   * Set up observable variables
   */
  constructor() {
    makeObservable(this, {
      dialog: observable,
      dialogContent: observable,
      dialogOnCancel: observable,
      dialogOnChange: observable,
      dialogOnConfirm: observable,
      dialogOnTimeout: observable,
      dialogTimeout: observable,
      dialogType: observable
    });
  }

  /**
   * Show a pop-up dialog box with the provided content and options
   * 
   * @function showDialog
   * @param {object} options
   * @param {object} options.content
   * @param {Function} [options.onCancel]
   * @param {Function} [options.onChange]
   * @param {Function} [options.onConfirm]
   * @param {Function} [options.onTimeout]
   * @param {Array} [options.selectOptions]
   * @param {number} [options.timeout]
   * @param {string} [options.type]
   */
  showDialog = action(options => {
    const {
      content,
      type,
      timeout,
      onConfirm,
      onCancel,
      onTimeout,
      onChange,
      selectOptions
    } = options;

    content && (this.dialogContent = content);
    type && (this.dialogType = type);
    timeout && (this.dialogTimeout = timeout);
    selectOptions && (this.dialogOptions = selectOptions);
    onConfirm && (this.dialogOnConfirm = onConfirm);
    onCancel && (this.dialogOnCancel = onCancel);
    onTimeout && (this.dialogOnTimeout = onTimeout);
    onChange && (this.dialogOnChange = onChange);

    this.dialog = true;
  })

  /**
   * Close the current pop-up dialog box
   * 
   * @function closeDialog
   */
  closeDialog = action(() => {
    this.dialog = false;
    this.dialogContent = null;
    this.dialogOnCancel = null;
    this.dialogOnChange = null;
    this.dialogOnConfirm = null;
    this.dialogOnTimeout = null;
    this.dialogTimeout = null;
    this.dialogType = null;

    // Focus the main content element
    const main = document.querySelector('#main-content');

    if (main) {
      main.focus();
    }
  })
}