import React from 'react';
import { action, makeObservable, observable } from 'mobx';

// Dependencies
import { getFirestore } from 'firebase/firestore'

/**
 * The main parent store which contains child
 * stores and widely-used actions (loading, error handling, etc.)
 * 
 * @function RootStore
 */
export default class RootStore {
  db = null;
  recordingChecked = false;
  recordingSupported = true;

  /**
   * Create the child store instances.
   * 
   * @function constructor
   * @param {Function} dialogStore
   */
  constructor(dialogStore) {
    this.dialogStore = dialogStore;

    makeObservable(this, {
      db: observable,
      loading: observable,
      loadingCalls: observable,
      loadingClass: observable,
      recordingChecked: observable,
      recordingSupported: observable
    });
  }

  /*-------------------------------------------
    Loading
  -------------------------------------------*/
  loading = false;
  loadingCalls = [];
  loadingClass = '';

  /**
   * Set the loading state to true and add the provided
   * request to the array of current requests
   * 
   * @function startLoading
   * @param {string} request
   */
  startLoading = action(request => {
    this.loading = true;
    this.loadingCalls.push(request)
  })

  /**
   * Remove the provided request to from the array of requests and
   * set the loading state to false if the request array is empty
   * 
   * @function finishLoading
   * @param {string} request - The name of the request being loaded
   */
  finishLoading = action(request => {
    const requestIndex = this.loadingCalls.indexOf(request);

    if (requestIndex >= 0) {
      this.loadingCalls.splice(requestIndex, 1);
    } else {
      this.loadingCalls.length = 0;
    }

    if (this.loadingCalls.length === 0 || typeof request === 'undefined') {
      this.loadingClass = 'leave';
      this.loading = false;

      // Wait for loader to fade out before removing
      setTimeout(() => {
        this.loadingClass = '';
        this.loadingMessage = null;
      }, 250);
    }
  })

  /*-------------------------------------------
    Error handling
  -------------------------------------------*/
  /**
   * Determine whether to show a specific or general error message
   * and then trigger the showing of the error
   * 
   * @function throwError
   * @param {string} errorMsg
   */
  throwError = errorMsg => {
    this.finishLoading();

    console.error(errorMsg);

    const dialogContent = {
      heading: 'Error',
      body: errorMsg,
      button: 'Okay'
    };

    this.dialogStore.showDialog({ content: dialogContent });
  }

  /*-------------------------------------------
    Firestore database
  -------------------------------------------*/
  /**
   * Initialize the Firestore database
   * 
   * @function initDB
   * @param {object} firebaseApp
   */
  initDB = action(firebaseApp => {
    this.db = getFirestore(firebaseApp);
  })
}

/**
 * Create a store provider to provide the store to components.
 * Used to wrap the main app instance in a store context provider.
 * 
 * @param {object} root0
 * @param {object} root0.children - The app instance
 * @param {object} root0.store - The RootStore class
 * @returns {Function}
 */
export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  )
};

/**
 * Create a store context to pass to components
 */
const StoreContext = React.createContext();

/**
 * Create a withStore function to allow components to use store
 * 
 * @param {Function} Component 
 * @returns {Function}
 */
export const withStore = Component => props => {
  return (
    <Component
      {...props}
      store={React.useContext(StoreContext)}
    />
  );
}