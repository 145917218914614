import React from 'react';
import { Link } from 'react-router-dom';

// Routes
import routes from '../../routes';

// Helpers
import { getUrlSegment } from '../../helpers';

// Store
import { withStore } from '../../stores/RootStore';

/**
 * Renders the main app header
 * 
 * @param {object} props
 * @returns {Function}
 */
const Footer = props => {
  // Get values from props
  const { translation } = props.store.translationStore;

  return (
    <footer className="app-foot">
      <nav className="app-foot__nav nav">
        <ul className="nav__list">
          <li>
            <Link
              className="nav__link"
              to={routes.privacy}
            >
              {translation?.Nav.privacy}
            </Link>
          </li>
          <li>
            <Link
              className="nav__link"
              to={routes.terms}
            >
              {translation?.Nav.terms}
            </Link>
          </li>
        </ul>
      </nav>

      <span className="app-foot__copyright">
        © {new Date().getFullYear()} {translation?.App.title}
      </span>
    </footer>
  )
}

export default withStore(Footer);