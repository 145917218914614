// Import React
import React from 'react';
import { render } from 'react-dom';

// Import stores and store provider
import store from './app/stores'
import { StoreProvider } from './app/stores/RootStore';

// Import styles
import './css/screen.css';

// Import components
import App from './app/components/App';

// Main app component
const app = (
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);

// Root DOM element
const rootEl = document.querySelector('#app');

render(app, rootEl);
