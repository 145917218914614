import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';

/**
 * Handle the app-wide translation
 * 
 * @function TranslationStore
 */
export default class TranslationStore {
  locale = 'en';
  translation = null;
  translationLoaded = false;

  /**
   * Add the RootStore for triggering the loading indicator
   * and set up observable variables
   * 
   * @param {Function} RootStore 
   */
  constructor(RootStore) {
    this.RootStore = RootStore;

    makeObservable(this, {
      locale: observable,
      translation: observable,
      translationLoaded: observable
    });
  }

  /**
   * Load the appropriate translation file
   * 
   * @function getTranslation
   */
  getTranslation = action(async () => {
    this.translationLoaded = true;

    const timestamp = (new Date()).getTime();

    this.RootStore.startLoading('getTranslation');

    return axios.get(`/json/${this.locale}.json?t=${timestamp}`)
      .then(action(response => {
        this.translation = response.data;
        this.RootStore.finishLoading('getTranslation');

        return response.data;
      }))
      .catch(error => {
        this.RootStore.throwError(error);
        return false;
      });
  })
}