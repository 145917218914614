const routes = {
  expired: '/session-expired',
	forgotPassword: '/forgot-password',
	index: '/',
	messages: '/messages',
	post: '/post/:postId',
	postNotFound: '/post-not-found',
	privacy: '/privacy',
	profile: '/:username',
	search: '/search',
	settings: '/settings',
	signIn: '/sign-in',
	signUp: '/sign-up',
	tags: '/tags/:tag',
	terms: '/terms',
	unauthorized: '/unauthorized'
};

export const protectedRoutes = [
	routes.settings, routes.index, routes.messages
]

export default routes;
