import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// Routes
import routes from '../../routes';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { checkValidity } from '../../helpers';

// Logo
import Logo from '../ui/Logo';

// Components
import Checkbox from '../common/Checkbox';

// Reserved usernames
const reservedUsernames = require('../../reserved_usernames.json');

/**
 * Renders the sign up view
 * 
 * @param {object} props
 * @returns {Function}
 */
const SignUp = observer(props => {
  // Get values from props
  const { store, translation } = props;
  const { rootStore, userStore } = store;
  const { loading } = rootStore;
  const { loggedIn } = userStore;

  // Create refs
  const emailField = React.useRef();
  const passwordField = React.useRef();
  const usernameField = React.useRef();

  // Set up state
  const [agreed, setAgreed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  // Set up navigate
  const navigate = useNavigate();

  // Main useEffect loop
  React.useEffect(() => {
    // If the user is already logged in,
    // send them to their home feed.
    if (loggedIn) {
      navigate('/');
    }
  }, []);

  /**
   * Make sure a username is available
   * 
   * @async
   * @function usernameFail
   * @returns {boolean}
   */
  const usernameFail = async () => {
    const field = usernameField.current;
    const username = field.value?.toLowerCase();

    if (!username) {
      return false;
    }

    const criteria = /^[a-z0-9][a-z0-9_]*([.][a-z0-9_]+)*$/;
    const meetsCriteria = criteria.test(username);

    const alreadyTaken = await userStore.getUsername(username);

    if (!meetsCriteria || alreadyTaken || reservedUsernames.indexOf(username) > -1) {
      field.setCustomValidity(translation.username.unavailable);
      field.reportValidity();
      return true;
    } else {
      field.setCustomValidity('');
      field.reportValidity();
      return false;
    }
  }

  /**
   * Submit the sign up form
   * 
   * @async
   * @function handleSubmit
   */
   const handleSubmit = async () => {
    setSubmitting(true);
    
    const invalidUsername = await usernameFail();

    if (invalidUsername) {
      setSubmitting(false);
      return;
    }
    
    const payload = {
      email: emailField.current.value,
      password: passwordField.current.value,
      username: usernameField.current.value
    };

    const loggedIn = await userStore.signUp(payload);

    if (loggedIn) {
      navigate('/');
    }
  }

  /**
   * Render links in the disclaimer message
   * 
   * @function renderDisclaimer
   * @returns {Function}
   */
   const renderDisclaimer = () => {
    const parts = translation.disclaimer.map((text, i) => {
      if(text[0] === '%' && text[text.length - 1] === '%') {
        const link = text.replace(/\%/g, '');
        return <Link key={i} to={routes[link]}>{translation[link]}</Link>;
      }

      return text;
    });

    const children = parts.reduce((result, el, i, array) => {
      if (i !== 0) {
        result.push(' ');
      }

      result.push(el);

      return result;
    }, []);

    return React.createElement('span', {}, children);;
  }

  // Set up form fields
  const fields = [{
    content: translation.email,
    id: 'email',
    ref: emailField,
    type: 'email'
  }, {
    content: translation.password,
    id: 'password',
    ref: passwordField,
    type: 'password'
  }, {
    content: translation.username,
    id: 'username',
    ref: usernameField,
    type: 'text'
  }];

  if (!loading) {
    return (
      <main
        aria-labelledby="page-title"
        className="page page--brief"
        id="main-content"
        tabIndex="-1"
      >
        <div className="panel panel--sm panel--solo">
          <i className="panel__brand">
            <Logo />
          </i>
  
          <h1
          className="panel__title panel__title--sm"
          id="page-title"
        >
            {translation.heading}
          </h1>
  
          <form
            className={`form ${submitting ? 'form--submitting' : ''}`}
            noValidate
            onSubmit={e => checkValidity(e, handleSubmit)}
          >
            <fieldset>
              <ul className="form__fields">
                {fields.map(field =>
                  <li key={field.id}>
                    <label
                      className="form__lbl"
                      htmlFor={field.id}
                    >
                      {field.content.label}
                    </label>
  
                    <input
                      aria-required="true"
                      className="form__field"
                      data-errormsg={field.content.error}
                      id={field.id}
                      onChange={e => e.target.setCustomValidity('')}
                      placeholder=" "
                      ref={field.ref}
                      required
                      type={field.type}
                    />
                  </li>
                )}

                <li>
                  <Checkbox 
                    errorMsg={translation.disclaimer_error}
                    id="sign-up-agree"
                    label={renderDisclaimer()}
                    onChange={() => setAgreed(!agreed)}
                    name="agree"
                    required
                    value={true}
                  />
                </li>
              </ul>
            </fieldset>
  
            <button
              className="btn btn--full"
              type="submit"
            >
              {translation.sign_in}
            </button>
          </form>
  
          <ul className="link-list panel__links">
            <li>
              <Link
                to={routes.signIn}
              >
                {translation.sign_in}
              </Link>
            </li>
            <li>
              {/* TODO: Add forgot password functionality */}
              <Link
                to={routes.forgotPassword}
              >
                {translation.forgot_password}
              </Link>
            </li>
          </ul>
        </div>
      </main>
    )
  }

  return null;
})

export default withStore(translate(SignUp, 'SignUp'))
