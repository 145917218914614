import React from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

// Dependencies
import Icon from 'alp-icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';
import Linkify from 'linkify-react';

// Config
import { config } from '../../config';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Components
import AudioPlayer from './AudioPlayer';
import Avatar from './Avatar';
import Dropdown from './Dropdown';
import PostVote from './PostVote';
import Tooltip from './Tooltip';

/**
 * Renders a load more button that can be used with endless scrolling
 * 
 * @param {object} props
 * @returns {Function}
 */

const Post = observer(props => {
  // Get values from props
  const { detailPage, postData, store, translation } = props;
  const { dialogStore, postStore, rootStore, toastStore, translationStore, userStore } = store;
  const { postAuthors } = postStore;
  const { loggedIn, userData } = userStore;
  const postOptions = [];

  // Set up state
  const [post, setPost] = React.useState(null);
  const [render, setRender] = React.useState(false);

  // Create refs
  const urlField = React.createRef();

  // Main useEffect loop
  React.useEffect(async () => {
    const skipStore = true;

    // Get the post author’s user data
    if (!postData.authorData) {
      if (!postAuthors[postData.postedBy]) {
        // If we haven’t already retrieved the author’s data, get it and store it
        const author = await userStore.getUserData(postData.postedBy, skipStore);
        postStore.storeAuthorData(author);
        postData.authorData = author;
      } else {
        // Otherwise, use the stored author data
        postData.authorData = postAuthors[postData.postedBy];
      }
    }

    setPost(postData);
    setRender(true);
  }, []);

  React.useEffect(() => {
    // If we’re on a post detail page, set the document title
    if (detailPage && post?.authorData) {
      document.title = `${translation.title.replace('%uname%', post.authorData.uname)} | ${translationStore.translation.App.title}`;
    }
  }, [detailPage, post]);

  // Add post options if the user is logged in
  if (loggedIn) {
    if (postData.postedBy !== userData?.uid) {
      // Allow non-authors to report the post
      postOptions.push({
        action: () => postStore.reportPost(postData, userData?.uid),
        icon: <Icon name="flag" />,
        title: translation.report
      });
    } else {
      // Allow authors to delete the post
      postOptions.push({
        action: async () => {
          dialogStore.showDialog({
            content: translation.delete_confirm,
            onConfirm: handleDelete,
            type: 'confirm'
          });
        },
        icon: <Icon name="trash" />,
        title: translation.delete
      });
    }
  }

  /**
   * Delete a post
   * 
   * @async
   * @function handleDelete
   */
  const handleDelete = async () => {
    const response = await postStore.deletePost(postData.postID);

    if (response.success) {
      toastStore.showToast('Post deleted', 'positive');
      rootStore.queryClient.invalidateQueries('listPosts');
    } else {
      toastStore.showToast('Sorry, something went wrong', 'negative');
    }
  }

  /**
   * Like or unlike a post
   * 
   * @async
   * @function handleLike
   */
  const handleLike = async e => {
    // Temporarily disable the button to prevent double clicks
    e.target.disabled = true;

    if (post.likedBy?.indexOf(userData?.uid) > -1) {
      // This user has already liked the post, so unlike it
      const success = await postStore.unlikePost(post, userData?.uid);

      if (success) {
        updateLikeCount('unlike');
      }
    } else {
      // Like the post
      const success = await postStore.likePost(post, userData?.uid);

      if (success) {
        updateLikeCount('like', e);
      }
    }

    e.target.disabled = false;
  }

  /**
   * Update the like count
   * 
   * @function updateLikeCount
   * @param {string} type
   */
  const updateLikeCount = (type, e) => {
    const postData = {...post};
    const likedBy = [...post.likedBy];
    const likeIndex = likedBy.indexOf(userData?.uid);

    if (likeIndex === -1 && type === 'like') {
      likedBy.push(userData?.uid);

      // Confetti animation
      setTimeout(() => {
        const btn = e.target;
        btn.classList.add('post__react--clicked');
      })
    } else {
      likedBy.splice(likeIndex, 1);
    }

    postData.likedBy = likedBy;

    setPost(postData);
  }

  /**
   * Copy the share URL if navigator.share() is not supported
   * 
   * @function copyShareUrl
   * @param {string} url
   */
  const copyShareUrl = url => {
    const field = urlField.current;
    const range = document.createRange();

    field.focus();
    document.execCommand('selectall');
    document.execCommand('copy');

    toastStore.showToast('Link copied', 'positive');
  }

  // Render the post
  if (post?.reportedBy?.indexOf(userData?.uid) > -1) {
    return null;
  }

  if (render) {
    if (post.reported) {
      return (
        <article className="post post--reported panel">
          <h3>{translation.reported}</h3>
        </article>
      )
    } else {
      return (
        <article className="post panel">
          <header className="post__head">
            <div className="post__user avatar-wrap">
              <Link to={`/${post.authorData.uname}`}>
                <Avatar
                  className="post__avatar"
                  currentUser={post.authorData?.uid === userData?.uid}
                  photoUrl={post.authorData.photoURL}
                  size="tiny"
                  username={post.authorData.uname}
                />
              </Link>
  
              <h3 className="post__author">
                <Link
                  className="title-link"
                  to={`/${post.authorData.uname}`}
                >
                  {post.authorData.uname}
                </Link>
              </h3>
            </div>

            <div className="post__data">
              {loggedIn &&
                <div className="post__actions">
                  <button
                    className={`post__react${
                      post.likedBy.indexOf(userData?.uid) > -1 ? ' post__react--selected' : ''
                    }`}
                    onClick={e => handleLike(e)}
                    type="button"
                  >
                    <Icon name="heart" />

                    {post.likedBy.length} <span className="meta">{post.likedBy.length === 1 ? translation.like : translation.likes}</span>
                  </button>

                  {/* <button
                    className={`post__react${
                      post.dislikedBy?.indexOf(userData?.uid) > -1 ? ' post__react--selected' : ''
                    }`}
                    onClick={() => console.log('dislike')}
                    type="button"
                  >
                    <Icon name="fingerMiddle" />

                    {post.dislikedBy?.length} <span className="meta">{post.dislikedBy?.length === 1 ? translation.dislike : translation.dislikes}</span>
                  </button> */}
                </div>
              }

              {!loggedIn &&
                <span className="post__react post__react--disabled">

                  <Tooltip
                    brief
                    content="Sign up or log in to like this post!"
                    hideLabel
                    icon={<Icon name="heartOutline" />}
                    left
                  />
                  
                  {post.likedBy.length} <span className="meta">{post.likedBy.length === 1 ? translation.like : translation.likes}</span>
                </span>
              }
            </div>
          </header>

          <div className="post__body">
            <AudioPlayer
              audioUrl={post.audioURL}
              autoplay={props.autoplay ? true : false}
              onComplete={async () => {
                const plays = await postStore.addPlay(post);
                const postData = {...post};

                postData.plays = plays;
                setPost(postData);
              }}
            />

            {post.text &&
              <div
                className="post__comments"
                id={`comments-${post.postID}`}
              >
                <Linkify
                  options={{
                    formatHref: {
                      hashtag: href => {

                        return `${config.domain}/tags/${href.replace('#', '')}`;
                      },
                      mention: href => {
                        return `${config.domain}${href}`;
                      }
                    }
                  }}
                >
                  {post.text}
                </Linkify>
              </div>
            }

            {/* Cheez or Tease voting */}
            {post.hashtags && post.hashtags.indexOf('cheezortease') > -1 &&
              <PostVote
                options={['🧀', '🤪']}
                post={post}
                title={'Cheez or Tease?'}
              />
            }

            {/* TODO: Possibly add commenting in the future */}
            {/* {(post.text || post.comments?.length > 0) &&
              <ul className="post__comments comment-list">
                {post.text &&
                  <li className="comment">
                    <span className="comment__author">
                      {post.userData.uname}
                    </span>
                    
                    <p className="comment__body">
                      {post.text}
                    </p>
                  </li>
                }
              </ul>
            } */}
          </div>

          <footer className="post__foot">
            <div className="post__metadata">
              {post.createdDate &&
                <time
                  className="post__pubdate"
                  dateTime={parseISO(new Date(post.createdDate._seconds * 1000).toISOString())}
                  pubdate="pubdate"
                >
                  <Link
                    className="title-link"
                    to={`/post/${post.postID}`}
                  >
                    {formatDistanceToNow(parseISO(new Date(post.createdDate._seconds * 1000).toISOString()), { addSuffix: true })}
                  </Link>
                </time>
              }

              <span className="post__plays">
                {post.plays?.length === 1 ? translation.play : translation.plays.replace('%count%', post.plays?.length || 0)}
              </span>
            </div>

            <div className="post__actions">
              <input
                className="input--hidden"
                ref={urlField}
                type="text"
                defaultValue={`${config.domain}/post/${post.postID}`}
              />

              <button
                className="btn btn--ghost btn--meta"
                onClick={async () => {
                  if (navigator.canShare) {
                    try {
                      const shareData = {
                        title: 'Cheezr',
                        text: post.text || 'Check out this post on Cheezr',
                        url: `/post/${post.postID}`
                      };
  
                      await navigator.share(shareData);
                    } catch(error) {
                      console.error(error);
                    }
                  } else {
                    copyShareUrl();
                  }
                }}
              >
                <Icon name="share" />
                <span className="meta">{translation.share}</span>
              </button>

              {loggedIn &&
                <Dropdown
                  className="dropdown--push-left"
                  buttonText={translation.options}
                  hideText
                  icon={<Icon name="ellipsis" />}
                  id={`${post.postID}-tools`}
                  options={postOptions}
                  triggerClassName="btn btn--ghost btn--meta"
                  variant="push-left"
                />
              }
            </div>
          </footer>
        </article>
      )
    }
  }

  return null;
});

export default withStore(translate(Post, 'Post'));
