import React from 'react';
import { action } from 'mobx';

// Dependencies
import Icon from 'alp-icons';

// Store
import { withStore } from '../../stores/RootStore';

// Components
import { assetUrl } from './AssetUrl';

/**
 * Renders a user’s avatar
 * 
 * @param {object} props
 * @property {string} className
 * @property {boolean} currentUser
 * @property {boolean} local
 * @property {string} photoUrl
 * @property {string} size
 * @property {string} username
 * @returns {Function}
 */
const Avatar = props => {
  // Get values from props
  const { className, local, photoUrl, size, store, username } = props;
  const { avatarStore } = store;
  const { userAvatars } = avatarStore;

  // Set up state
  const [avatarUrl, setAvatarUrl] = React.useState(userAvatars[username] ? userAvatars[username] : null);

  // Create refs
  const _isMounted = React.useRef(false);

  // Main useEffect loop
  React.useEffect(() => {
    _isMounted.current = true;

    if (!avatarUrl || photoUrl !== avatarUrl) {
      handlePhotoUrl();
    }
  }, [photoUrl]);

  // Cleanup
  React.useEffect(() =>  {
    return () => {
      _isMounted.current = false;
    }
  }, []);

  /**
   * Get the provide photo’s full url
   * 
   * @function handlePhotoUrl
   */
  const handlePhotoUrl = async () => {
    if (photoUrl && !local) {
      const url = await assetUrl(photoUrl);

      avatarStore.storeAvatarUrl(username, url);

      if (_isMounted.current) {
        setAvatarUrl(url);
      }
    } else if (photoUrl && local && _isMounted.current) {
      setAvatarUrl(photoUrl);
    }
  }

  return (
    <figure
      className={`avatar${
        size ? ` avatar--${size}` : ''
      }${
        className ? ` ${className}` : ''
      }`}
    >
      {avatarUrl &&
        <img
          alt={`${username}’s avatar`}
          className="avatar__img"
          src={avatarUrl || ''}
        />
      }

      {!avatarUrl &&
        <Icon name="person" />
      }
    </figure>
  )
}

export default withStore(Avatar);
