import React from 'react';

/**
 * Renders the home view
 * 
 * @param {object} props
 * @returns {Function}
 */
const PullToRefresh = props => {
  // Get values from props
  const { children, maxPull, onRefresh } = props;

  // Set up state
  const [mouseStartY, setMouseStartY] = React.useState(0);
  const [mouseYDiff, setMouseYDiff] = React.useState(0);

  // Create refs
  const dragContainer = React.useRef(null);

  /**
   * Hide the default drag preview
   * 
   * @function setupDrag
   * @param {event} e
   */
  const setupDrag = e => {
    const ghost = e.target.cloneNode(true);
    ghost.style.backgroundColor = 'red';
    ghost.style.display = 'none';
    document.body.appendChild(ghost);

    // e.dataTransfer.setDragImage(ghost, 0, 0);
    setMouseStartY(e.touches[0].clientY);
  }

  /**
   * Handle dragging
   * 
   * @function handleDrag
   * @param {event} e
   */
  const handleDrag = e => {
    if (window.scrollY > 0) {
      return;
    }

    let dragAmount = e.touches[0].clientY - mouseStartY;

    if (dragAmount > maxPull) {
      dragAmount = maxPull;
    }

    setMouseYDiff(dragAmount);
  }

  const handleDragEnd = () => {
    setMouseYDiff(0);

    if (onRefresh) {
      onRefresh();
    }
  }

  return (
    <div
      className={`pull${
        mouseYDiff ? ' pull--active' : ''
      }`}
      draggable
      onTouchEnd={handleDragEnd}
      onTouchStart={e => setupDrag(e)}
      onTouchMove={e => handleDrag(e)}
      ref={dragContainer}
    >
      {/* {showLoader &&
        <strong className="pull__loader loader--async">
          <span className="meta">
            Loading
          </span>
        </strong>
      } */}

      <span
        className={`pull__text${
          mouseYDiff ? ' pull__text--active' : ''
        }`}
      >
        {mouseYDiff < maxPull ? 'Pull to refresh' : 'Release to refresh'}
      </span>

      <div
        className="pull__children"
        style={{ top: mouseYDiff || 0 }}
      >
        {children}
      </div>
    </div>
  )
}

export default PullToRefresh;