import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useInfiniteQuery } from 'react-query';

// Config
import { config } from '../../config';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { getUrlSegment } from '../../helpers';

// Dependencies
import Icon from 'alp-icons';

// Components
import Avatar from '../common/Avatar';
import Error404 from './Error404';
import LoadMore from '../common/LoadMore';
import { assetUrl } from '../common/AssetUrl';
import Post from '../common/Post';

/**
 * Renders the profile view
 * 
 * @param {object} props
 * @returns {Function}
 */
const Profile = observer(props => {
  // Get values from props
  const { store, translation } = props;
  const { postStore, userStore } = store;
  const { loggedIn, userAvatar, userData } = userStore;

  // Set up state
  const [error404, setError404] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [photoUrl, setPhotoUrl] = React.useState(null);
  const [posts, setPosts] = React.useState([]);
  const [profileData, setProfileData] = React.useState(null);
  const [render, setRender] = React.useState(false);

  // Set up location tracking
  const { pathname } = useLocation();

  // Main useEffect loop
  React.useEffect(async () => {
    const username = await userStore.getUsername(getUrlSegment(1).toLowerCase());

    console.log(username);

    if (!username) {
      setError404(true);
      setRender(true);
    } else {
      const skipStore = true;
      const profile = await userStore.getUserData(username.user, skipStore);

      let photo;

      if (userData?.uname === getUrlSegment(1) && userAvatar) {
        photo = userAvatar;
      } else {
        photo = await assetUrl(profile.photoURL);
      }
      setPhotoUrl(photo);
      setProfileData(profile);
      setRender(true);
    }
  }, [pathname, userData?.photoURL]);

  // Get the post data
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching
  } = useInfiniteQuery('listPosts', ({ pageParam = 0 }) => postStore.listPostsRest({
    limit: config.listPostLimit,
    user: [profileData?.uid],
    offset: pageParam
  }), {
    getNextPageParam: (lastPage, pages) => {
      // If the most recent page is identical to the last page in we got,
      // then we have reached the end and there is no next page.
      if (JSON.stringify(lastPage) === JSON.stringify(pages[pages.length - 2])) {
        return false;
      }

      return offset + config.listPostLimit;
    },
    enabled: profileData !== null
  });

  // Store the post data in state
  React.useEffect(() => {
    let postArr = [];

    if (data?.pages?.length) {
      data.pages.forEach(page => {
        postArr = postArr.concat(page.results);
      })

      setPosts(postArr);
    }
  }, [data?.pages]);

  /**
   * Upload a new avatar
   * 
   * @async
   * @function handleNewAvatar
   * @param {Event} e
   */
  const handleNewAvatar = async e => {
    const { files } = e.target;
    
    if (files[0]) {
      const success = await userStore.updateAvatar(files[0]);

      // HACK: Doing this to avoid complicated updating
      // of post author data after an avatar changes.
      // Come back and do something better here.
      if (success) {
        window.location.reload();
      }
    }
  }

  /**
   * Render a follow or unfollow button
   * 
   * @function renderFollowBtn
   */
   const renderFollowBtn = () => {
    let className, onClick, text, icon;

    if(userData?.follows.indexOf(profileData.uid) > -1) {
      // Logged in user follows this user
      onClick = () => userStore.unfollowUser(profileData.uid);
      text = translation.unfollow.replace('%username%', profileData.uname);
      className = 'profile__follow btn btn--outline btn--pill btn--negative icon--md';
      icon = <Icon name="personMinus" />;
    } else {
      // Logged in user does not follow this user
      onClick = () => userStore.followUser(profileData.uid);
      text = translation.follow.replace('%username%', profileData.uname);
      className = 'profile__follow btn btn--pill icon--md';
      icon = <Icon name="personPlus" />;
    }

    return (
      <button
        className={className}
        onClick={onClick}
      >
        {icon}
        {text}
      </button>
    )
  }

  if (!error404 && render) {
    return (
      <main
        aria-labelledby="page-title"
        className="page"
        id="main-content"
        tabIndex="-1"
      >
        <section className="page__primary">
          <header
            className="profile__head"
          >
            <img
              aria-hidden="true"
              className="profile__hero"
              role="presentation"
              src={photoUrl ? photoUrl : 'http://assets.sbnation.com/assets/199301/do-not-want-dog.jpg'}
              alt=""
            />

            <div className="profile__head-wrap">
              <h2
                className="profile__title"
                id="page-title"
              >
                {profileData.uname}
              </h2>

              <div className="profile__avatar-wrap">
                <Avatar
                  className="profile__avatar"
                  currentUser={profileData.uname === userData?.uname}
                  photoUrl={profileData.photoURL}
                  size="large"
                  username={profileData.uname}
                />

                {/* Edit avatar button */}
                {loggedIn && profileData.uid === userStore.userData?.uid &&
                  <React.Fragment>
                    <label
                      className="profile__edit-avatar btn btn--round"
                      htmlFor="new-avatar"
                    >
                      <Icon name="camera" />

                      <span className="meta">
                        {translation.edit_avatar}
                      </span>
                    </label>

                    <input
                      accept="image/*"
                      className="input--file"
                      id="new-avatar"
                      onChange={handleNewAvatar}
                      type="file"
                    />
                  </React.Fragment>
                }
              </div>

              {/* Follow button */}
              {loggedIn && profileData.uid !== userData?.uid &&
                renderFollowBtn()
              }

              {profileData.uid === userData?.uid &&
                <span className="profile__follow">{translation.your_profile}</span>
              }
            </div>
          </header>

          {posts.length === 0 &&
            <span className="no-results">
              {translation.no_posts}
            </span>
          }

          {posts.length > 0 &&
            <ol className="post-list">
              {posts.map((post) =>
                <li key={post.postID}>
                  <Post postData={post} />
                </li>
              )}
            </ol>
          }

          {isFetching &&
            <strong className="loader--async">
              <span className="meta">
                {translation.loading}
              </span>
            </strong>
          }

          {!isFetching && hasNextPage &&
            <LoadMore
              infiniteScroll
              onClick={() => {
                fetchNextPage();
                setOffset(offset + config.listPostLimit);
              }}
              text={translation.load_more}
            />
          }
        </section>
      </main>
    )
  } else if (error404 && render) {
    return <Error404 />
  }

  return null;
})

export default withStore(translate(Profile, 'Profile'));
