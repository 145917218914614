import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { getUrlSegment } from '../../helpers';

// Components
import Post from '../common/Post';

/**
 * Renders a post detail view
 * 
 * @param {object} props
 * @returns {Function}
 */
const PostDetail = observer(props => {
  // Get values from props
  const { store, translation } = props;
  const { postStore } = store;

  // Set up state
  const [post, setPost] = React.useState(null);
  const [render, setRender] = React.useState(false);

  // Set up navigate
  const navigate = useNavigate();

  // Fetch the post data
  const { data, isError, isLoading } = useQuery('getPost', () => postStore.getPostRest(getUrlSegment(2)), {
    retry: false
  });

  // Store the post data in state
  React.useEffect(() => {
    if (!isLoading && data) {
      setPost(data);
      setRender(true);
    }
  }, [data]);

  React.useEffect(() => {
    if(isError) {
      navigate('/post-not-found');
    }
  }, [isError]);

  if (render) {
    return (
      <main
        aria-labelledby="page-title"
        className="page"
        id="main-content"
        tabIndex="-1"
      >
        <section className="page__primary">
          <h2
            className="meta"
            id="page-title"
          >
            {translation.heading.replace('%user%', post.postedBy)}
          </h2>
  
          <Post
            autoplay
            detailPage
            postData={post}
          />
        </section>
      </main>
    )
  }

  return null;
})

export default withStore(translate(PostDetail, 'PostDetail'));
