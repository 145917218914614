import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// Routes
import routes from '../../routes';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { checkValidity } from '../../helpers';

// Logo
import Logo from '../ui/Logo';

/**
 * Renders the sign in view
 * 
 * @param {object} props
 * @returns {Function}
 */
const SignIn = observer(props => {
  // Get values from props
  const { store, translation } = props;
  const { rootStore, userStore } = store;
  const { loading } = rootStore;
  const { loggedIn } = userStore;

  // Create refs
  const emailField = React.useRef();
  const passwordField = React.useRef();

  // Set up navigate
  const navigate = useNavigate();

  // Main useEffect loop
  React.useEffect(() => {
    // If the user is already logged in,
    // send them to their home feed.
    if (loggedIn) {
      navigate('/');
    }
  }, []);

  /**
   * Submit the sign in form
   * 
   * @async
   * @function handleSubmit
   */
  const handleSubmit = async () => {
    const payload = {
      email: emailField.current.value,
      password: passwordField.current.value
    };

    const loggedIn = await userStore.signIn(payload);

    if (loggedIn) {
      navigate('/');
    }
  }

  // Set up form fields
  const fields = [{
    content: translation.email,
    id: 'email',
    ref: emailField,
    type: 'email'
  }, {
    content: translation.password,
    id: 'password',
    ref: passwordField,
    type: 'password'
  }];

  if (!loading) {
    return (
      <main
        aria-labelledby="page-title"
        className="page page--brief"
        id="main-content"
        tabIndex="-1"
      >
        <div className="panel panel--sm panel--solo">
          <i className="panel__brand">
            <Logo />
          </i>
  
          <h1
          className="panel__title panel__title--sm"
          id="page-title"
        >
            {translation.heading}
          </h1>
  
          <form
          className="form"
          noValidate
          onSubmit={e => checkValidity(e, handleSubmit)}
          >
            <fieldset>
              <ul className="form__fields">
                {fields.map(field =>
                  <li key={field.id}>
                    <label
                      className="form__lbl"
                      htmlFor={field.id}
                    >
                      {field.content.label}
                    </label>
  
                    <input
                      aria-required="true"
                      className="form__field"
                      data-errormsg={field.content.error}
                      id={field.id}
                      placeholder=" "
                      ref={field.ref}
                      required
                      type={field.type}
                    />
                  </li>
                )}
              </ul>
            </fieldset>
  
            <button
              className="btn btn--full"
              type="submit"
            >
              {translation.sign_in}
            </button>
          </form>
  
          <ul className="link-list panel__links">
            <li>
              <Link
                to={routes.signUp}
              >
                {translation.sign_up}
              </Link>
            </li>
            <li>
              {/* TODO: Add forgot password functionality */}
              <Link
                to={routes.forgotPassword}
              >
                {translation.forgot_password}
              </Link>
            </li>
          </ul>
        </div>
      </main>
    )
  }

  return null;
})

export default withStore(translate(SignIn, 'SignIn'));
