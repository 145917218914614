import React from 'react';

// Helpers
import { parseSeconds } from '../../helpers';

/**
 * Renders a countdown component
 * 
 * @param {object} props
 * @property {number} timeout The countdown length in seconds
 * @property {string} [className]
 * @property {Function} [onTimeout] Optional callback to run after countdown reaches 0
 * @returns {Function}
 */
const Countdown = props => {
  // Get values from props
  const { className, onTimeout } = props;

  // Set up state
  const [timeout, updateTimeout] = React.useState(props.timeout);

  // Main useEffect hook
  React.useEffect(() => {
    // Start the countdown timer.
    const timer = setInterval(handleCountdown, 1000);

    // Clear the timer on when it hits 0
    if (timeout === 0) {
      clearInterval(timer);

      if (onTimeout) {
        onTimeout();
      }
    }

    // Clear the timer on unmount
    return () => {
      clearInterval(timer);
    }
  }, [timeout]);

  /**
   * Count the time down.
   * 
   * @function handleCountdown
   */
  const handleCountdown = () => {
    // Subtract 1 second
    // Note: The timeout variable is a representation
    // of seconds rather than milliseconds primarily
    // because the API tends to return things in seconds
    updateTimeout(timeout - 1);
  };

  return (
    <div
      id="timer-root"
      role="timer"
      aria-live="off"
      className={`countdown${className ? ` ${className}` : ""}`}
    >
      {(timeout || timeout === 0) && parseSeconds(timeout)}
    </div>
  );
}

export default Countdown;
