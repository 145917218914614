import React from 'react';

// Dependencies
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import Icon from 'alp-icons';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Helpers
import { parseSeconds } from '../../helpers';

/**
 * Renders an audio player
 * 
 * @param {object} props
 * @returns {Function}
 */
const AudioPlayer = props => {
  // Get values from props
  const { audioUrl, autoplay, local, minimal, onComplete, translation } = props;

  // Set up state
  const [audioSrc, setAudioSrc] = React.useState(null);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [playing, setPlaying] = React.useState(false);

  // Create refs
  const _isMounted = React.useRef(false);
  const audioPlayer = React.createRef();

  // Main useEffect loop
  React.useEffect(async () => {
    _isMounted.current = true;

    if (local) {
      setAudioSrc(audioUrl);
    } else {
      const storage = getStorage();
      const audioRef = ref(storage, audioUrl);

      await getDownloadURL(audioRef)
        .then(url => {
          if (_isMounted.current) {
            setAudioSrc(url);
          }
        })
        .catch(error => {
          if (error.code === 'storage/quota-exceeded' && !window.overQuota) {
            window.overQuota = true;

            alert('Cheezr is over capacity because I’m not going to pay for actual hosting right now and too much data has been transferred. No audio will load until the quota resets. And you can’t post new stuff either. And also avatars won’t load. Sorry, bucko.');
          }
        })
    }
  }, []);

  // Cleanup
  React.useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    }
  });

  /**
   * Play and pause the audio
   * 
   * @function handlePlayback
   */
  const handlePlayback = () => {
    const audioEl = audioPlayer.current;

    if (!playing) {
      audioEl.play();
    } else {
      audioEl.pause();
    }
  }

  // Render the audio player
  if (audioSrc) {
    return (
      <div className={`audio${
        minimal ? ' audio--minimal' : ''
      }`}>
        <audio
          autoPlay={autoplay ? true : false}
          className="audio__src"
          onDurationChange={e => {
            // HACK: Sometimes the duration comes
            // through as Infinity, but temporarily
            // setting a currentTime fixes the issue.
            if (e.target.duration === Infinity) {
              e.target.currentTime = 1000000000;
              
              setTimeout(() => {
                e.target.currentTime = 0;
              }, 10)
            } else if (_isMounted.current) {
              setDuration(e.target.duration)
            }
          }}
          onEnded={() => {
            setCurrentTime(0);
            setPlaying(false);

            if (onComplete) {
              onComplete();
            }
          }}
          onPause={() => setPlaying(false)}
          onPlay={() => setPlaying(true)}
          onTimeUpdate={e => setCurrentTime(e.target.currentTime)}
          ref={audioPlayer}
        >
          <source src={audioSrc} />
        </audio>

        <button
          className={`audio__control${
            minimal ? ' btn--reset' : ' btn btn--round'
          }`}
          onClick={handlePlayback}
          type="button"
        >
          {playing ? <Icon name="pause" /> : <Icon name="play" />}

          <span className="meta">
            {playing ? translation.pause : translation.play}
          </span>
        </button>

        <progress
          className="audio__progress"
          max={duration}
          value={currentTime}
        >
          {currentTime}
        </progress>

        <span className="audio__time">
          {parseSeconds(Math.floor(currentTime))}/{parseSeconds(Math.floor(duration))}
        </span>
      </div>
    )
  } else {
    return (
      <div className="audio audio--loading">
        <span className="meta">
          {translation.loading}
        </span>
      </div>
    )
  }
}

export default withStore(translate(AudioPlayer, 'AudioPlayer'));
