import React from 'react';
import { observer } from 'mobx-react-lite';

/**
 * Returns the translation for a given component
 * 
 * @function translate
 * @param {Function} Component
 * @param {string} componentName
 * @returns {object}
 */
const translate = (Component, componentName) => {
  /**
   * Create a translation component to return
   * 
   * @param {object} props
   * @returns {Function}
   */
  const TranslationComponent = props => {
    // Get values from props
    const { translationStore } = props.store;

    if (translationStore.translation && translationStore.translation[componentName]) {
      return (
        <Component
          {...props}
          translation={translationStore.translation[componentName]}
        />
      )
    } else {
      return (
        <span className="no-results">
          Translation not found
        </span>
      )
    }
  }

  return observer(TranslationComponent);
}

export default translate;