import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useInfiniteQuery } from 'react-query';

// Config
import { config } from '../../config';

// Routes
import routes from '../../routes';

// Store
import { withStore } from '../../stores/RootStore';

// Translation
import translate from '../Translate';

// Components
import LoadMore from '../common/LoadMore';
import Post from '../common/Post';
import PullToRefresh from '../common/PullToRefresh';

/**
 * Renders the home view
 * 
 * @param {object} props
 * @returns {Function}
 */
const Home = observer(props => {
  // Get values from props
  const { store, translation } = props;
  const { postStore, userStore } = store;
  const { userData } = userStore;

  // Set up state
  const [offset, setOffset] = React.useState(0);
  const [posts, setPosts] = React.useState([]);

  // Create an array of the users to get posts from
  const userArr = [userData.uid, ...userData.follows];

  // Get the post data
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching
  } = useInfiniteQuery('listPosts', ({ pageParam = 0 }) => postStore.listPostsRest({
    limit: config.listPostLimit,
    user: userArr,
    offset: pageParam
  }), {
    getNextPageParam: (lastPage, pages) => {
      // If the most recent page is identical to the last page in we got,
      // then we have reached the end and there is no next page.
      if (JSON.stringify(lastPage) === JSON.stringify(pages[pages.length - 2])) {
        return false;
      }

      return offset + config.listPostLimit;
    }
  });

  // Store the post data in state
  React.useEffect(() => {
    let postArr = [];

    if (data?.pages?.length) {
      data.pages.forEach(page => {
        postArr = postArr.concat(page.results);
      })

      setPosts(postArr);
    }
  }, [data?.pages]);

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <h2
        className="meta"
        id="page-title"
      >
        {translation.heading}
      </h2>

      <section className="page__primary">
        {/* TODO: Maybe show this message if there are also no posts */}
        {(userData?.follows === undefined || userData?.follows?.length === 0) &&
          <span className="no-results">
            {translation.no_friends} <Link to={routes.search}>
              {translation.search}
            </Link>
          </span>
        }

        {posts.length > 0 &&
          <PullToRefresh
            maxPull={100}
            onRefresh={() => postStore.listPosts(config.listPostLimit)}
            showLoader={isFetching}
          >
            <ol
              className="post-list"
            >
              {posts.map(post =>
                <li key={post.postID}>
                  <Post postData={post} />
                </li>  
              )}
            </ol>
          </PullToRefresh>
        }

        {isFetching &&
          <strong className="loader--async">
            <span className="meta">
              {translation.loading}
            </span>
          </strong>
        }

        {!isFetching && hasNextPage &&
          <LoadMore
            infiniteScroll
            onClick={() => {
              fetchNextPage();
              setOffset(offset + config.listPostLimit);
            }}
            text={translation.load_more}
          />
        }
      </section>
    </main>
  )
})

export default withStore(translate(Home, 'Home'));
